import {
    BLOG_LOAD_DATA,
    BLOG_DATA_LOADED,
    BLOG_LATEST_LOADED
} from "constants/ActionTypes";

const INIT_STATE = {
    blogLoading: true,
    page:1,
    blogData:null,
    blogLatest:null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case BLOG_LOAD_DATA: {
            return {
                ...state,
                blogLoading:true,
                payload:1
            }
        }        
        case BLOG_DATA_LOADED: {
            return {
                ...state,
                blogLoading:false,
                blogData:action.payload,
                page:action.payload.current_page
            }
        }
        case BLOG_LATEST_LOADED: {
            return {
                ...state,
                blogLatest:action.payload
            }
        }
        default:
            return state;
    }
}
