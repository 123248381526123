import {
    SUBMIT_CONTACT_FORM,
    CONTACT_FORM_SUBMITED
} from "constants/ActionTypes";

const INIT_STATE = {
    contactLoading: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SUBMIT_CONTACT_FORM: {
            return {
                ...state,
                contactLoading:true,
                payload:1
            }
        }        
        case CONTACT_FORM_SUBMITED: {
            return {
                ...state,
                contactLoading:false,
            }
        }
        default:
            return state;
    }
}
