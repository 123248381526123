// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu';
export const DRAWER_TYPE = 'drawer_type';
export const FIXED_DRAWER = 'fixed_drawer';
export const COLLAPSED_DRAWER = 'collapsible';
export const MINI_DRAWER = 'mini_drawer';
export const THEME_COLOR = 'theme_color';
export const DARK_THEME = 'dark_theme';
export const WINDOW_WIDTH = 'window-width';
export const SWITCH_LANGUAGE = 'switch-language';
export const SWITCH_CURRENCY = 'switch-currency';
export const LOAD_CURRENCIES = 'load-currencies';
export const CHANGE_DIRECTION = 'change-direction';

export const CHANGE_NAVIGATION_STYLE = 'change-navigation-style';
export const HORIZONTAL_NAVIGATION = 'horizontal_navigation';
export const VERTICAL_NAVIGATION = 'vertical_navigation';

export const HORIZONTAL_MENU_POSITION = 'horizontal_menu_position';
export const ABOVE_THE_HEADER = 'above_the_header';
export const INSIDE_THE_HEADER = 'inside_the_header';
export const BELOW_THE_HEADER = 'below_the_header';

export const SET_GLOBAL_CONFIG = 'set_global_config';
export const GET_GLOBAL_CONFIG = 'get_global_config';

export const GET_LOCAL_CURRENCIE = 'get_local_currencie';

//Contact Module const

export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';

export const FETCH_ALL_CONTACT = 'fetch_all_contact';
export const FETCH_ALL_CONTACT_SUCCESS = 'fetch_all_contact_success';
export const ADD_FAVOURITE = 'add_favourite';
export const ON_CONTACT_SELECT = 'on_contact_select';
export const ON_ADD_CONTACT = 'on_add_contact';
export const ON_CONTACT_CLOSE = 'on_contact_close';
export const ON_FILTER_OPTION_SELECT = 'on_filter_option_select';
export const ON_SAVE_CONTACT = 'on_save_contact';
export const ON_DELETE_CONTACT = 'on_delete_contact';
export const ON_DELETE_SELECTED_CONTACT = 'on_delete_selected_contact';
export const FILTER_CONTACT = 'filter_contact';
export const GET_ALL_CONTACT = 'get_all_contact';
export const GET_UNSELECTED_ALL_CONTACT = 'get_unselected_all_contact';
export const ON_ALL_CONTACT_SELECT = 'on_all_contact_select';
export const UPDATE_SEARCH_USER = 'update_search_user';
export const ON_TOGGLE_DRAWER = 'on_toggle_drawer';
export const HANDLE_REQUEST_CLOSE = 'handle_request_close';
export const HIDE_CONTACT_LOADER = 'hide_contact_loader';

//Auth const
export const SIGNUP_USER = 'signup_user';
export const SIGNUP_USER_SUCCESS = 'signup_user_success';
export const SIGNIN_GOOGLE_USER = 'signin_google_user';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'signin_google_user_success';
export const SIGNIN_FACEBOOK_USER = 'signin_facebook_user';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'signin_facebook_user_success';
export const SIGNIN_TWITTER_USER = 'signin_twitter_user';
export const SIGNIN_TWITTER_USER_SUCCESS = 'signin_twitter_user_success';
export const SIGNIN_GITHUB_USER = 'signin_github_user';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_SUCCESS';
export const SIGNIN_USER = 'signin_user';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const INIT_URL = 'init_url';
export const PRELOAD_USERDATA = 'preload_user_data';
export const APP_SEND_RECOVERY = 'app_send_recovery';
export const APP_RECOVERY_SUCCESS = 'app_recovery_success';
export const HIDE_RECOVERY_MESSAGE = 'hide_recovery_message';
export const APP_CHANGE_PASSWORD = 'app_change_password';
export const APP_CHANGE_PASSWORD_SUCCESS = 'app_change_password_success';
export const APP_UPLOAD_PHOTO = 'app_upload_photo';
export const APP_UPLOAD_PHOTO_SUCCESS = 'app_upload_photo_success';
export const ACTIVE_NOTIFICATION = 'active_notification';


// Chat Module const

export const FETCH_ALL_CHAT_USER = 'fetch_all_chat_user';
export const FETCH_ALL_CHAT_USER_CONVERSATION = 'fetch_all_chat_user_conversation';
export const FETCH_ALL_CHAT_USER_SUCCESS = 'fetch_all_chat_user_success';
export const FETCH_ALL_CHAT_USER_CONVERSATION_SUCCESS = 'fetch_all_chat_user_conversation_success';
export const FILTER_USERS = 'filter_users';
export const ON_SELECT_USER = 'on_select_user';
export const ON_SHOW_LOADER = 'on_show_loader';
export const ON_HIDE_LOADER = 'on_hide_loader';
export const USER_INFO_STATE = 'user_info_state';
export const SUBMIT_COMMENT = 'submit_comment';
export const UPDATE_MESSAGE_VALUE = 'update_message_value';
export const UPDATE_SEARCH_CHAT_USER = 'update_search_chat_user';

//// Mail Module const


export const ADD_LABEL = 'ADD_LABEL';
export const GET_ALL_MAIL = 'get_all_mail';
export const FETCH_ALL_MAIL = 'fetch_all_mail';
export const FETCH_ALL_MAIL_SUCCESS = 'fetch_all_mail_success';
export const GET_IMPORTANT_MAIL = 'get_important_mail';
export const GET_NAV_FILTERS = 'get_nav_filters';
export const GET_NAV_LABELS = 'get_nav_labels';
export const GET_NAV_FOLDER = 'get_nav_folder';
export const GET_READ_MAIL = 'get_read_mail';
export const GET_STARRED_MAIL = 'get_starred_mail';
export const GET_UNIMPORTANT_MAIL = 'get_unimportant_mail';
export const GET_UNREAD_MAIL = 'get_unread_mail';
export const GET_UNSELECTED_ALL_MAIL = 'get_unselected_all_mail';
export const GET_UNSTARRED_MAIL = 'get_unstarred_mail';
export const ON_ALL_MAIL_SELECT = 'on_all_mail_select';
export const ON_FOLDER_MENU_ITEM_SELECT = 'on_folder_menu_item_select';
export const ON_FOLDER_SELECT = 'on_folder_select';
export const ON_IMPORTANT_SELECT = 'on_important_select';
export const ON_LABEL_MENU_ITEM_SELECT = 'on_label_menu_item_select';
export const GET_MARK_AS_START = 'get_mark_as_start';
export const ON_LABEL_SELECT = 'on_label_select';
export const ON_MAIL_CHECKED = 'on_mail_checked';
export const ON_MAIL_SELECT = 'on_mail_select';
export const ON_MAIL_SEND = 'on_mail_send';
export const ON_OPTION_MENU_ITEM_SELECT = 'on_option_menu_item_select';
export const ON_OPTION_MENU_SELECT = 'on_option_menu_select';
export const ON_START_SELECT = 'on_start_select';
export const SEARCH_MAIL = 'search_mail';
export const ON_DELETE_MAIL = 'on_delete_mail';
export const SET_CURRENT_MAIL_NULL = 'set_current_mail_null';
export const ON_COMPOSE_MAIL = 'on_compose_mail';

//// TO-DO Module const

export const ON_SORTEND = 'on_sortend';
export const FETCH_ALL_TODO = 'fetch_all_todo';
export const FETCH_ALL_TODO_SUCCESS = 'fetch_all_todo-success';
export const FETCH_ALL_TODO_CONVERSATION = 'fetch_all_todo_conversation';
export const FETCH_ALL_TODO_CONVERSATION_SUCCESS = 'fetch_all_todo_conversation_success';
export const SELECT_ALL_TODO = 'select_all_todo';
export const GET_ALL_TODO = 'get_all_todo';
export const GET_UNSELECTED_ALL_TODO = 'get_unselected_all_todo';
export const GET_STARRED_TODO = 'get_starred_todo';
export const GET_UNSTARRED_TODO = 'get_unstarred_todo';
export const GET_IMPORTANT_TODO = 'get_important_todo';
export const GET_UNIMPORTANT_TODO = 'get_unimportant_todo';
export const ON_LABEL_UPDATE = 'on_label_update';
export const ON_TODO_UPDATE = 'on_todo_update';
export const ON_DELETE_TODO = 'on_delete_todo';
export const SEARCH_TODO = 'search_todo';
export const SHOW_TODOS = 'show_todos';
export const GET_TODO_CONVERSATION = 'get_todo_conversation';
export const ON_TODO_CHECKED = 'on_todo_checked';
export const ON_TODO_ADD = 'on_todo_add';
export const ON_TODO_SELECT = 'on_todo_select';
export const SET_CURRENT_TODO_NULL = 'set_current_todo_null';
export const REMOVE_LABEL = 'remove_label';
export const UPDATE_SEARCH = 'update_search';


// BLOG
export const BLOG_LOAD_DATA = 'blog_load_data';
export const BLOG_DATA_LOADED = 'blog_data_loaded';

export const BLOG_LOAD_LATEST = 'blog_load_latest';
export const BLOG_LATEST_LOADED = 'blog_latest_loaded';
// BLOG


// Profile
export const GET_PROFILE_INFO = 'get_profile_info';
export const PROFILE_INFO_LOADED = 'profile_data_loaded';
// Profile

// Register
export const SEND_SIGNUP_DATA = 'send_signup_data';
export const SIGNUP_SUCCESS = 'signup_success';
export const USER_SIGNUP_ERROR = 'user_signup_error';
export const SEND_SIGNUP_DIRECT_DATA = 'send_signup_direct_data'

// Register

//AUTOCOMPLETE

export const AUTOCOMPLETE_SEARCH_DATA = 'autocomplete_search_data';
export const AUTOCOMPLETE_DATA_LOADED = 'autocomplete_data_loaded';
export const AUTOCOMPLETE_SEARCH_CARTA = 'autocomplete_search_carta';
export const AUTOCOMPLETE_CARTA_LOADED = 'autocomplete_carta_loaded';

//AUTOCOMPLETE

//APP
export const APP_INTERNAL_ERROR = 'app_internal_error';
//APP

// MICE
export const MICE_SAVE = 'mice_save';
export const MICE_DATA_SAVED = 'mice_data_save';
// MICE

//APP
export const VALIDATION_ERROR = 'validation_error';
export const INTERNAL_SERVER_ERROR = "internal_server_error";
export const UNKNOWN_ERROR = "unknown_error";
export const MODAL_ERROR_HIDE = 'modal_error_hide';
export const MODAL_ERROR_SHOW = 'modal_error_show';
export const ERROR_MESSAGE = 'error_message';
export const MAIN_ALERT_HIDE = "main_alert_hide";
export const MAIN_ALERT_SHOW = "main_alert_show";
export const OPEN_MODAL_LOGIN = 'open_modal_login';
export const CLOSE_MODAL_LOGIN = 'opem_modal_login';
export const DISABLED_BUTTON_SEARCH = 'disabled_button_search';
export const ALERT_SHOW_HIDE = 'alert_show_hide';
//APP

//CONTACT

export const SUBMIT_CONTACT_FORM = "submit_contanct_form";
export const CONTACT_FORM_SUBMITED = "contact_form_submited";

//CONTACT

//WORK WITH US

export const SUBMIT_WORK_WITH_US_FORM = "submit_work_with_us_form";
export const WORK_WITH_US_FORM_SUBMITTED = "work_with_us_form_submitted";

//WORK WITH US

//IPORS

export const SUBMIT_IPQRS_FORM = "submit_ipqrs_form";
export const IPQRS_FORM_SUBMITTED = "ipqrs_form_submitted"
export const IPQRS_ALERT_HIDE = "ipqrs_alert_hide";
export const IPQRS_ALERT_SHOW = "ipqrs_alert_show";

//IPQRS

//BOOKING

export const BOOKING_ADD_TO_CART = "booking_add_to_cart";
export const BOOKING_REMOVE_FROM_CART = "booking_remove_from_cart";
export const BOOKING_CLEAR_CART = "BOOKING_CLEAR_CART";
export const BOOKING_SHOW_CART = "booking_show_cart";
export const BOOKING_HIDE_CART = "booking_hide_cart";
export const BOOKING_TOGGLE_CART = "booking_toggle_cart";
export const BOOKING_SEARCH_LOAD = "booking_search_load";
export const UPDATE_SEARCH_PARAMS = "update_search_params";
export const UPDATE_SEARCH_PARAMS_HOTEL = "UPDATE_SEARCH_PARAMS_HOTEL";
export const BOOKING_SEARCH_LOADED = "booking_search_loaded";
export const PUNTO_CARTA_LOAD = "punto_carta_load";
export const LOAD_SERVICIO_DETAIL = "load_servicio_detail";
export const SERVICIO_DETAIL_LOADED = "servicio_detail_loaded";
export const CLEAR_SEARCH = "clear_search";
export const RESERV_CONF_OF_SERVICE_QUERY = "reserv_conf_of_service_query";
export const RESERV_CONF_OF_SERVICE_DATA = "reserv_conf_of_service_data";
export const RESERV_CONF_OF_SERVICE_CLEAR = "reserv_conf_of_service_clear";
export const RESERV_PRICE_QUERY = "RESERV_PRICE_query";
export const RESERV_PRICE_DATA = "RESERV_PRICE_data";
export const RESERV_PRICE_CLEAR = "RESERV_PRICE_clear";
export const BOOKING_LOCK_DROPDOWN_CART = "booking_lock_dropdown_cart";
export const RESERV_QUOTE_SAVE = "reserv_quote_save";
export const RESERV_QUOTE_RESPONSE = "reserv_quote_response";
export const RESERV_QUOTE_ERROR = "reserv_quote_error";
export const QUOTE_CHECKOUT_SAVE = "quote_checkout_save";
export const QUOTE_CHECKOUT_RESPONSE = "quote_checkout_response";
export const RESERV_CHECKOUT_SAVE = "reserv_checkout_save";
export const RESERV_CHECKOUT_RESPONSE = "reserv_checkout_response";
export const RESERV_CHECKOUT_CLEAR = "reserv_checkout_clear";
export const RESERV_EDIT_MODE_ON = "reserv_edit_mode_on";
export const RESERV_EDIT_MODE_OFF = "reserv_edit_mode_off";
export const BOOKING_SEARCH_HOTEL_LOAD = "booking_search_hotel_load";
export const BOOKING_SEARCH_HOTEL_LOADED = "booking_search_hotel_loaded";
export const CLEAR_HOTEL_SEARCH = "clear_hotel_search";
export const HOTEL_ACOMODACION_REQUEST = "hotel_acomodacion_request";
export const HOTEL_ACOMODACION_RESPONSE = "hotel_acomodacion_response";
export const HOTEL_ZONAS_REQUEST = "hotel_zonas_request";
export const HOTEL_ZONAS_RESPONSE = "hotel_zonas_response";
export const HOTEL_PRICE_ACOMODACION_REQUEST = "hotel_price_acomodacion_request";
export const HOTEL_PRICE_ACOMODACION_RESPONSE = "hotel_price_acomodacion_response";
export const ERASE_HOTELES_CART = "erase_hoteles_cart";
export const CHANGE_ORIGEN = "change_origen";
export const HOTEL_DISPONIBILIDAD_REQUEST = "hotel_disponibilidad_request";
export const HOTEL_DISPONIBILIDAD_RESPONSE = "hotel_disponibilidad_response";
export const HOTEL_DISPONIBILIDADES_REQUEST = "hotel_disponibilidades_request";
export const HOTEL_DISPONIBILIDADES_RESPONSE = "hotel_disponibilidades_response";
export const SEARCH_DESTINATION_POINT = "search_destination_point";

//BOOKING

//WIDGETS

export const FEATURED_LOAD = "wigdet_featured_load";
export const FEATURED_LOADED = "wigdet_featured_loaded";


//WIDGETS

// DESTINATION

export const DEST_LOADING = "dest_loading";
export const DEST_DESTINATION_REQUESTED = "dest_destination_requested";
export const DEST_TARIFF_REQUESTED = "dest_tariff_requested";
export const DEST_VIEW_MODE = "dest_view_mode";
export const DEST_DATA_LOADED = "dest_data_loaded";

// DESTINATION


// PAGES

export const POST_FEATURED_REQUESTED = "post_featured_requested";
export const POST_PAGES_REQUESTED = "post_pages_requested";
export const POST_PAGE_REQUESTED = "post_page_requested";
export const POST_DATA_LOADED = "post_data_loaded";
export const POST_LOADING = "post_loading";

// PAGES

// PROFILE

export const PROFILE_RESERVAS_REQUEST = "profile_reservas_request";
export const PROFILE_RESERVAS_DATA = "profile_reservas_data";

export const PROFILE_PAGOS_REQUEST = "profile_pagos_request";
export const PROFILE_PAGOS_DATA = "profile_pagos_data";

export const PROFILE_RESERVA_REQUEST = "profile_reserva_request";
export const PROFILE_RESERVA_DATA = "profile_reserva_data";

export const PROFILE_IMPRIMIR_LIQUIDACION_REQUEST = "profile_imprimir_liquidacion_request";
export const PROFILE_IMPRIMIR_LIQUIDACION_DATA = "profile_imprimir_liquidacion_data";

export const PROFILE_IMPRIMIR_VOUCHER_REQUEST = "profile_imprimir_voucher_request";
export const PROFILE_IMPRIMIR_VOUCHER_DATA = "profile_imprimir_voucher_data";

export const PROFILE_CANCELAR_RESERVA_REQUEST = "profile_cancelar_reserva_request";
export const PROFILE_CANCELAR_RESERVA_DATA = "profile_cancelar_reserva_data";

export const PROFILE_REACTIVAR_RESERVA_REQUEST = "profile_reactivar_reserva_request";
export const PROFILE_REACTIVAR_RESERVA_DATA = "profile_reactivar_reserva_data";

export const PROFILE_GUARDAR_PASAJEROS_REQUEST = "profile_guardar_pasajeros_request";
export const PROFILE_GUARDAR_PASAJEROS_DATA = "profile_guardar_pasajeros_data";
export const PROFILE_INICIAR_TRANSACCION_REQUEST = "profile_iniciar_transaccion_request";

export const PROFILE_EXTRACT_REQUEST = "profile_extract_request";
export const PROFILE_EXTRACT_DATA = "profile_extract_data";

export const PROFILE_GARANTIZAR_RESERVA_REQUEST = 'profile_garantizar_reserva_request';
export const PROFILE_GARANTIZAR_RESERVA_DATA = 'profile_garantizar_reserva_data';

// PROFILE

// OPERATIONS

export const OPERATIONS_SERVICIOS_REQUEST = "operations_servicios_request";
export const OPERATIONS_SERVICIOS_LOADED = "operations_servicios_loaded";
export const OPERATIONS_EXTRACTOS_REQUEST = "operations_extractos_request";
export const OPERATIONS_EXTRACTOS_LOADED = "operations_extractos_loaded";
export const OPERATIONS_CHANGE_SERVICIO_STATE = "operations_change_servicio_state";
export const OPERATIONS_SERVICIO_DETALLE_REQUEST = "operations_servicio_detalle_request";
export const OPERATIONS_SERVICIO_DETALLE_LOADED = "operations_servicio_detalle_loaded";
// OPERATIONS

// PAGO
export const GET_PAYMENT_STATUS = 'get_payment_status';
export const PAYMENT_STATUS_LOADED = 'payment_status_loaded';
// PAGO

// HOTEL CONFIRM
export const GET_HOTEL_CONFIRMED = 'get_hotel_confirmed';
export const HOTEL_CONFIRMED_LOADED = 'hotel_confirmed_loaded';
// HOTEL CONFIRM

// EMPRESA

export const GET_EMPRESA_REQUEST = "get_empresa_request";
export const GET_EMPRESA_DATA = "get_empresa_data";

// EMPRESA