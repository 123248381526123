import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IntlMessages from 'util/IntlMessages';
export default class Confirm extends React.Component {
  render() {
    const {content,title, open, hideConfirm} = this.props;

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
        >

          <DialogTitle id="form-dialog-title"><IntlMessages id={title}/></DialogTitle>
          <DialogContent><DialogContentText><IntlMessages id={content}/></DialogContentText></DialogContent>

          <DialogActions>
            <Button onClick={() => hideConfirm(false)} color="secondary">
                <IntlMessages id='button.no'/>
            </Button>
            <Button onClick={() => hideConfirm(true)} color="primary" autoFocus>
                <IntlMessages id='button.yes'/>
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
