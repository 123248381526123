import {
	PROFILE_PAGOS_REQUEST,
	PROFILE_PAGOS_DATA,
	PROFILE_RESERVAS_REQUEST,
	PROFILE_RESERVAS_DATA,
	PROFILE_RESERVA_REQUEST,
	PROFILE_RESERVA_DATA,
	PROFILE_IMPRIMIR_LIQUIDACION_REQUEST,
	PROFILE_IMPRIMIR_LIQUIDACION_DATA,
	PROFILE_IMPRIMIR_VOUCHER_REQUEST,
	PROFILE_IMPRIMIR_VOUCHER_DATA,
	PROFILE_CANCELAR_RESERVA_REQUEST,
	PROFILE_CANCELAR_RESERVA_DATA,
	PROFILE_REACTIVAR_RESERVA_REQUEST,
	PROFILE_REACTIVAR_RESERVA_DATA,
	PROFILE_GUARDAR_PASAJEROS_REQUEST,
	PROFILE_GUARDAR_PASAJEROS_DATA,
	PROFILE_INICIAR_TRANSACCION_REQUEST,
	PROFILE_EXTRACT_REQUEST,
	PROFILE_EXTRACT_DATA,
	PROFILE_GARANTIZAR_RESERVA_REQUEST,
	PROFILE_GARANTIZAR_RESERVA_DATA,
} from 'constants/ActionTypes';

export const iniciarTransaccionRequest = (payload, callback) => {
	return {
		type: PROFILE_INICIAR_TRANSACCION_REQUEST,
		payload: payload,
		callback: callback,
	};
};
export const pagosRequest = (payload, callback) => {
	return {
		type: PROFILE_PAGOS_REQUEST,
		payload: payload,
		callback: callback,
	};
};

export const pagosData = (payload) => {
	return {
		type: PROFILE_PAGOS_DATA,
		payload: payload,
	};
};
export const reservasRequest = (payload, callback) => {
	return {
		type: PROFILE_RESERVAS_REQUEST,
		payload: payload,
		callback: callback,
	};
};

export const reservasData = (payload) => {
	return {
		type: PROFILE_RESERVAS_DATA,
		payload: payload,
	};
};

export const reservaRequest = (payload, callback) => {
	return {
		type: PROFILE_RESERVA_REQUEST,
		payload: payload,
		callback: callback,
	};
};

export const reservaData = (payload) => {
	return {
		type: PROFILE_RESERVA_DATA,
		payload: payload,
	};
};

export const imprimirLiquidacionRequest = (payload, callback) => {
	return {
		type: PROFILE_IMPRIMIR_LIQUIDACION_REQUEST,
		payload: payload,
		callback: callback,
	};
};

export const imprimirLiquidacionData = (payload) => {
	return {
		type: PROFILE_IMPRIMIR_LIQUIDACION_DATA,
		payload: payload,
	};
};

export const imprimirVoucherRequest = (payload, callback) => {
	return {
		type: PROFILE_IMPRIMIR_VOUCHER_REQUEST,
		payload: payload,
		callback: callback,
	};
};

export const imprimirVoucherData = (payload) => {
	return {
		type: PROFILE_IMPRIMIR_VOUCHER_DATA,
		payload: payload,
	};
};

export const cancelarReservaRequest = (payload, callback) => {
	return {
		type: PROFILE_CANCELAR_RESERVA_REQUEST,
		payload: payload,
		callback: callback,
	};
};

export const cancelarReservaData = (payload) => {
	return {
		type: PROFILE_CANCELAR_RESERVA_DATA,
		payload: payload,
	};
};

export const reactivarReservaRequest = (payload, callback) => {
	return {
		type: PROFILE_REACTIVAR_RESERVA_REQUEST,
		payload: payload,
		callback: callback,
	};
};

export const reactivarReservaData = (payload) => {
	return {
		type: PROFILE_REACTIVAR_RESERVA_DATA,
		payload: payload,
	};
};

export const guardarPasajerosRequest = (payload, callback) => {
	return {
		type: PROFILE_GUARDAR_PASAJEROS_REQUEST,
		payload: payload,
		callback: callback,
	};
};

export const guardarPasajerosData = (payload) => {
	return {
		type: PROFILE_GUARDAR_PASAJEROS_DATA,
		payload: payload,
	};
};


export const extractRequest = (payload, callback) => {
	return {
		type: PROFILE_EXTRACT_REQUEST,
		payload: payload,
		callback: callback,
	};
};

export const extractData = (payload) => {
	return {
		type: PROFILE_EXTRACT_DATA,
		payload: payload,
	};
};

export const garantizarReservaRequest = (payload, callback) => {
	return {
		type: PROFILE_GARANTIZAR_RESERVA_REQUEST,
		payload: payload,
		callback: callback,
	};
};

export const garantizarReservaData = (payload) => {
	return {
		type: PROFILE_GARANTIZAR_RESERVA_DATA,
		payload: payload,
	};
};