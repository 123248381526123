import React from 'react';
import {connect} from 'react-redux'
import {userSignOut} from 'actions/Auth';
import IntlMessages from 'util/IntlMessages';
import { Link } from 'react-router-dom'

class UserInfoPopup extends React.Component {
	render() {
		return (
			<div>
				<div className="user-profile">
					<div className="user-detail ml-2">
						<h4 className="user-name mb-0">{this.props.authUser.nombre || "User"}</h4>
						<small>{this.props.authUser.user}</small>
					</div>
				</div>

				<Link to="/profile" className="jr-link dropdown-item text-muted">
					<i className="zmdi zmdi-face zmdi-hc-fw mr-1"/>
					<IntlMessages id="popup.profile"/>
				</Link>

				{this.props.authUser.id_tipo_usuario !== 19 &&
					<Link to="/reserva" className="jr-link dropdown-item text-muted">
						<i className="zmdi zmdi-storage zmdi-hc-fw mr-1"/>
						<IntlMessages id="popup.misReservas"/>
					</Link>
				}


				{this.props.authUser.id_tipo_usuario === 19 &&
					<Link to="/operaciones" className="jr-link dropdown-item text-muted">
						<i className="zmdi zmdi-settings zmdi-hc-fw mr-1"/>
						<IntlMessages id="app.operaciones"/>
					</Link>
				}

				<span className="jr-link dropdown-item text-muted" onClick={() => {
					localStorage.removeItem("token_id");
					localStorage.removeItem("tempCurrency");
					localStorage.removeItem("suscrito");
					localStorage.removeItem("edit_extra");
					window.location.reload();
					this.props.userSignOut()
				}}>
					<i className="zmdi zmdi-sign-in zmdi-hc-fw mr-1"/>
					<IntlMessages id="popup.logout"/>
				</span>

			</div>
		);
	}
}

export default connect(null, {userSignOut})(UserInfoPopup);
