import {
	GET_HOTEL_CONFIRMED,
	HOTEL_CONFIRMED_LOADED,
} from 'constants/ActionTypes';

export const getHotelConfirm = (page) => {
	return {
		type: GET_HOTEL_CONFIRMED,
		payload: page,
	};
};

export const hotelConfirmLoaded = (data) => {
	return {
		type: HOTEL_CONFIRMED_LOADED,
		payload: data
	};
};