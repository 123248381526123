import {
    AUTOCOMPLETE_SEARCH_DATA,
	AUTOCOMPLETE_DATA_LOADED,
	AUTOCOMPLETE_CARTA_LOADED
} from "constants/ActionTypes";

const INIT_STATE = {
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case AUTOCOMPLETE_SEARCH_DATA: {
            return {
                ...state,
                searching:true,
            }
        }
        case AUTOCOMPLETE_DATA_LOADED: {
            let data = {
                ...state,
                searching:false,
            };
            data[action.payload.id] = action.payload;
            return data;
		}
		case AUTOCOMPLETE_CARTA_LOADED: {
			let data = {
				...state
			};
			data[action.payload.id] = action.payload;
			return data;
		}
        default:
            return state;
    }
}
