import {all, call, fork, put, takeEvery} from "redux-saga/effects";

// import {blogDataLoaded} from "actions/Blog";
import Api from "util/Api";
import { AUTOCOMPLETE_SEARCH_DATA, AUTOCOMPLETE_SEARCH_CARTA } from "../constants/ActionTypes";
import { autoCompleteDataLoaded } from "../actions/AutoComplete";
// import { puntoSearched } from "actions/Booking";


const loadDataFnRequest = async (url,data) =>
    await  Api.get(url,data)
        .then(authUser => authUser)
        .catch(error => error);

function* loadDataFn({payload}) {
    const {url,callback,id,data} = payload;
    try {
        const response = yield call(loadDataFnRequest,url,data);
        if (response.response.status === 200) {
            // localStorage.removeItem('token_id');
            let items = response.items.map((item) => { return {label:item.nombre,value:item.id,item:item}});

            callback(items);
            yield put(autoCompleteDataLoaded({
                items:response.items,
                total:response.total,
                id
            }));
        } else {
            // alert(response.response.status)
            //localStorage.setItem('token_id', page);
            // yield put(blogDataLoaded(response));
        }
    } catch (error) {
        // yield put(blogDataLoaded(error));
    }
}

export function* loadAutoCompleteData() {
    yield takeEvery(AUTOCOMPLETE_SEARCH_DATA, loadDataFn);
}

//
const loadDataFnRequestCarta = async (url,data) =>
    await  Api.get(url,data)
        .then(authUser => authUser)
        .catch(error => error);

function* loadCartaFn({payload}) {
    const {url,data, callback} = payload;
    try {
		const response = yield call(loadDataFnRequestCarta,url,data);

        if (response.response.status === 200) {
            // localStorage.removeItem('token_id');
			let items = response.items.map((item) => { return {label:item.nombre,value:item.id,item:item}});
			callback(items, response.total);
            // yield put(puntoSearched({
            //     items:items,
            //     id
            // }));
        } else {
			callback([], 0);
            // alert(response.response.status)
            //localStorage.setItem('token_id', page);
            // yield put(blogDataLoaded(response));
        }
    } catch (error) {
        // yield put(blogDataLoaded(error));
    }
}

export function* loadAutoCompleteCarta() {
    yield takeEvery(AUTOCOMPLETE_SEARCH_CARTA, loadCartaFn);
}



export default function* rootSaga() {
    yield all([
		fork(loadAutoCompleteData),
		fork(loadAutoCompleteCarta)
    ]);
}