import RTL from 'util/RTL';
import ChangePassword from './ChangePassword';
import ForgotPassword from './ForgotPassword';
import SignIn from './SignIn';
import SignUp from './SignUp';
import ComingSoon from './comingSoon';
import "assets/vendors/style";
import MainApp from 'app/index';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import AppLocale from '../lngProvider';
import {IntlProvider} from 'react-intl';
import MomentUtils from '@date-io/moment';
import {setInitUrl} from '../actions/Auth';
import {preloadUserData} from '../actions/Auth';
import defaultTheme from './themes/defaultTheme';
import asyncComponent from 'util/asyncComponent';
import {Redirect, Route, Switch} from 'react-router-dom';
import {MuiPickersUtilsProvider} from 'material-ui-pickers';
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import AuthRoute from './AuthRoute';


class App extends Component {

  componentWillMount() {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
    // if(this.props.logged){
      this.props.preloadUserData(this.props.userToken);
    // }
  }

  render() {

    const {match, location, locale, logged, initURL, isDirectionRTL,ready, authUser} = this.props;
    if(!ready){
      return (<div className="loader">
          <svg className="circular" viewBox="25 25 50 50">
              <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10"/>
          </svg>
      </div>);
    }

    if (location.pathname === '/') {
      if (logged === null || (authUser && authUser.invitado)) {
        return ( <Redirect to={'/signin'}/> );
      } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
        return ( <Redirect to={'/sample-page'}/> );
      } else {
        return ( <Redirect to={initURL}/> );
      }
    }
    const applyTheme = createTheme(defaultTheme);

    if (isDirectionRTL) {
      applyTheme.direction = 'rtl';
      document.body.classList.add('rtl')
    } else {
      document.body.classList.remove('rtl');
      applyTheme.direction = 'ltr';
    }
    const currentAppLocale = AppLocale[locale.locale];
    return (
      <MuiThemeProvider theme={applyTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>
            <RTL>
              <div className="app-main">
                <Switch>
                  <AuthRoute path='/changepassword' component={ChangePassword}  logged={logged} />
                  <AuthRoute path='/forgotpassword' component={ForgotPassword}  logged={logged} />
                  <AuthRoute path='/signin' component={SignIn}  logged={logged} />
                  <AuthRoute path='/signup' component={SignUp}  logged={logged} />
				  <AuthRoute path='/coming-soon' component={ComingSoon}  logged={logged} />
                  <Route path={`${match.url}`} logged={logged}
                                   component={MainApp}/>
                  <Route
                    component={asyncComponent(() => import('components/Error404'))}/>
                </Switch>
              </div>
            </RTL>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ({settings, auth}) => {
  const {sideNavColor, locale, isDirectionRTL} = settings;
  const {userToken, initURL,logged,ready} = auth;
  return {sideNavColor, locale, isDirectionRTL, userToken, initURL,logged,ready}
};

export default connect(mapStateToProps, {setInitUrl,preloadUserData})(App);
