import {
	SUBMIT_WORK_WITH_US_FORM,
	WORK_WITH_US_FORM_SUBMITTED
} from "constants/ActionTypes";

const INIT_STATE = {
	data: [],
	workWithUsLoading: false,
	showModal: false,
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case SUBMIT_WORK_WITH_US_FORM: {
			return {
				...state,
				workWithUsLoading: true,
			}
		}
		case WORK_WITH_US_FORM_SUBMITTED: {
			return {
				...state,
				workWithUsLoading: false,
				data: action.payload
			}
		}
		default:
			return state;
	}
}
