/* import appActions from '../redux/app/actions';

 */
/* const {
	toggleErrorUnauthorized
} = appActions; */


let unAauthorization = false;
let tokenAauthorization = null;
let langHeader = null;
let currencyHeader = null;
const fetchUrl = process.env.REACT_APP_SERVER_ENDPOINT;

const customHeader = () => ({
	'Content-Type': 'application/json',
	'Accept': 'application/json',
	'App-id':process.env.REACT_APP_APP_ID,
	'Authorization': `Bearer ${tokenAauthorization}`,
	'X-lang':`${langHeader}`,
	'X-currency':`${currencyHeader}`
});
const uploadHeader = () => ({
	'Accept': 'application/json',
	'App-id':process.env.REACT_APP_APP_ID,
	'Authorization': `Bearer ${tokenAauthorization}`,
	'X-lang':`${langHeader}`,
	'X-currency':`${currencyHeader}`
});
export const setAuthHeader = token => {
	tokenAauthorization = token;
}
export const setLangHeader = lang => {
	langHeader = lang;
}
export const setCurrencyHeader = currency => {
	currencyHeader = currency;
}

const base = (method, url, data = {}) => {
	let fetchUrlApi = url;
	let body = {};
	let originalUrl = url;
	let excludePaths = ["auth/logout", "auth/login"];

	if(url.indexOf("://") !== -1 ){
		if(method!=='get'){
			body.body = JSON.stringify(data);
		}
		fetchUrlApi = url;
	}else{
		if(method!=="upload"){
			body = {method:(method === "upload"?"post":method), headers: customHeader(()=>{
				return localStorage.getItem("token_id")
			})};
		}else{
			body = {method:(method === "upload"?"post":method), headers: uploadHeader(()=>{
				return localStorage.getItem("token_id")
			})};
		}

		if(method==='get'){
			let paramans = [''];
			Object.keys(data).map((key)=>{
				return paramans.push(`${key}=${data[key]}`);
			});
			paramans = paramans.join("&");
			url = `${url}?${paramans}`;
		}else if(method !== "upload"){
			body.body = JSON.stringify(data);
		}else{
			let formData = new FormData();
			for(var m in data){
				formData.append(m,data[m]);
			}
			body.body = formData;
		}
		fetchUrlApi = `${fetchUrl}${url}`;
	}

	return fetch(fetchUrlApi, body)
		.then(resp =>{
			if (resp.headers.get("x-token") !== "" && resp.headers.get("x-token") !== null) {
				tokenAauthorization = resp.headers.get("x-token");
				Api.tokenAauthorization = tokenAauthorization;
			}
			return resp.json().then(json => {
				json.response = {
					status:resp.status
				};
				return json;
			});
		})
		.then(res => {
			if (parseInt(res.status_code, 10) === 401 && excludePaths.indexOf(originalUrl) < 0 && unAauthorization === false) {
				unAauthorization = true;
				alert("Session expired");
				window.location.reload(false);
			}
			return res;
		})
		.catch(error => {
			return error;
		});
};
const Api = {url:fetchUrl,tokenAauthorization:tokenAauthorization,tk:(token)=>{
	tokenAauthorization = token;
}};
['get', 'post', 'put', 'delete','upload'].forEach(method => {
	Api[method] = base.bind(null, method);
});
export default Api;
