import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import Api from "util/Api";
import { paymentStatusLoaded } from "../actions/PaymentStatus";
import { handleRequestException } from "../actions/App";
import { GET_PAYMENT_STATUS } from "../constants/ActionTypes";

const paymentStatusFnRequest = async (payload) =>
	await Api.get('reserva/pago', payload)
		.then(authUser => authUser)
		.catch(error => error);

export function* paymentStatus() {
	yield takeEvery(GET_PAYMENT_STATUS, function* ({ payload }) {
		try {
			const response = yield call(paymentStatusFnRequest, payload);
			if (response.response.status === 200) {
				yield put(paymentStatusLoaded(response));
			} else {
				yield [
					put(handleRequestException(response))
				];
			}
		} catch (error) {
			console.error(error)
		}
	});
}

export default function* rootSaga() {
	yield all([
		fork(paymentStatus)
	])
}