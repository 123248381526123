import {
	PROFILE_RESERVAS_REQUEST,
	PROFILE_RESERVAS_DATA,
	PROFILE_PAGOS_REQUEST,
	PROFILE_PAGOS_DATA,
	PROFILE_RESERVA_REQUEST,
	PROFILE_RESERVA_DATA,
	PROFILE_EXTRACT_REQUEST,
	PROFILE_EXTRACT_DATA,
	PROFILE_GARANTIZAR_RESERVA_REQUEST,
	PROFILE_GARANTIZAR_RESERVA_DATA,
} from "constants/ActionTypes";

const INIT_STATE = {
	loading: false,
	loadingE: false,
	loadingG: false,
	pagos: [],
	reservas: [],
	reserva: [],
	extract: false,
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case PROFILE_PAGOS_REQUEST: {
			return {
				...state,
				loading:true,
			}
		}
		case PROFILE_PAGOS_DATA: {
			return {
				...state,
				loading:false,
				pagos:action.payload,
			}
		}
		case PROFILE_RESERVAS_REQUEST: {
			return {
				...state,
				loading:true,
			}
		}
		case PROFILE_RESERVAS_DATA: {
			return {
				...state,
				loading:false,
				reservas:action.payload,
			}
		}
		case PROFILE_RESERVA_REQUEST: {
			return {
				...state,
				loading:true,
			}
		}
		case PROFILE_RESERVA_DATA: {
			return {
				...state,
				loading:false,
				reserva:action.payload,
			}
		}
		case PROFILE_EXTRACT_REQUEST: {
			return {
				...state,
				loadingE:true,
			}
		}
		case PROFILE_EXTRACT_DATA: {
			return {
				...state,
				loadingE:false,
				extract:action.payload,
			}
		}
		case PROFILE_GARANTIZAR_RESERVA_REQUEST: {
			return {
				...state,
				loadingG:true,
			}
		}
		case PROFILE_GARANTIZAR_RESERVA_DATA: {
			return {
				...state,
				loadingG:false,
			}
		}
		default:
			return state;
	}
}
