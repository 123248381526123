import {
    POST_FEATURED_REQUESTED,
    POST_PAGES_REQUESTED,
    POST_PAGE_REQUESTED,
    POST_DATA_LOADED,
    POST_LOADING,
} from 'constants/ActionTypes';

const INIT_STATE = {
    loadingMask: false,
    featured: [],
    pages: [],
    page: null
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case POST_FEATURED_REQUESTED:
        case POST_PAGES_REQUESTED:
        case POST_PAGE_REQUESTED: {
            return {
                ...state,
                loadingMask:true,
            }
        }
        case POST_DATA_LOADED:
        {
            return {
                ...state,
                loadingMask:false,
                [action.origen]: action.payload,
            }
        }
        case POST_LOADING: {
            return {
                ...state,
                loadingMask:action.loading,
            }
        }
        default:
            return state;
    }
}
