import Api from './Api';

class AuthHelper {
	accountSession = async data => {
		return await Api.get('account/session', data).then(response => {
			return response;
		});
	};
	login = async (username,password) => {
		if (!username) {
			return { error: 'please fill in the input' };
		}
		return await Api.post('auth/login', {
            email:username,
            password:password
        }).then(response => {
			return response;
		}).catch(error => {
            return error;
        });
	};
	logout = async () => {
		return await Api.post('auth/logout');
	};
	getUserData =  (token) => {
		Api.tk(token);
		return  Api.get("auth/pm/user");
	};
}

export default new AuthHelper();
