import {
	HIDE_MESSAGE,
	INIT_URL,
	ON_HIDE_LOADER,
	ON_SHOW_LOADER,
	SHOW_MESSAGE,
	SIGNIN_FACEBOOK_USER_SUCCESS,
	SIGNIN_GITHUB_USER_SUCCESS,
	SIGNIN_GOOGLE_USER_SUCCESS,
	SIGNIN_TWITTER_USER_SUCCESS,
	SIGNIN_USER_SUCCESS,
	SIGNOUT_USER_SUCCESS,
	SIGNUP_USER_SUCCESS,
	PRELOAD_USERDATA,
	PROFILE_INFO_LOADED,
	USER_SIGNUP_ERROR,
	APP_SEND_RECOVERY,
	APP_RECOVERY_SUCCESS,
	APP_CHANGE_PASSWORD,
	APP_CHANGE_PASSWORD_SUCCESS,
	APP_UPLOAD_PHOTO,
	APP_UPLOAD_PHOTO_SUCCESS
} from "constants/ActionTypes";
import { HIDE_RECOVERY_MESSAGE } from "../constants/ActionTypes";

const INIT_STATE = {
	loader: false,
	alertMessage: '',
	showMessage: false,
	showPasswordMessage: false,
	ready:false,
	profileLoaded:true,
	initURL: 'home',
	userToken: localStorage.getItem('token_id'),
	logged: localStorage.getItem('token_id') !== "" && localStorage.getItem('token_id') != null
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case PRELOAD_USERDATA: {
			return {
				...state,
				loader:true,
				authUser:action.payload
			}
		}
		case PROFILE_INFO_LOADED: {
			return {
				...state,
				// authUser:action.payload.data,
				profileLoaded:true,
			}
		}
		case SIGNUP_USER_SUCCESS: {
			return {
				...state,
				loader: false,
				showMessage:true,
				alertMessage:action.payload.message
			}
		}
		case SIGNIN_USER_SUCCESS: {
			return {
				...state,
				loader: false,
				logged:true,
				ready:true,
				authUser: action.payload.authUser,
				accessToken:action.payload.accessToken?	action.payload.accessToken:action.payload.access_token,
				userToken:action.payload.accessToken?	action.payload.accessToken:action.payload.acess_token
			}
		}
		case INIT_URL: {
			return {
				...state,
				initURL: action.payload
			}
		}
		case SIGNOUT_USER_SUCCESS: {
			return {
				...state,
				authUser: null,
				userToken:null,
				logged:false,
				ready:true,
				accessToken:null,
				initURL: 'home',
				loader: false
			}
		}

		case SHOW_MESSAGE: {
			return {
				...state,
				alertMessage: action.payload,
				showMessage: true,
				loader: false
			}
		}
		case HIDE_MESSAGE: {
			return {
				...state,
				alertMessage: '',
				showMessage: false,
				loader: false
			}
		}

		case SIGNIN_GOOGLE_USER_SUCCESS: {
			return {
				...state,
				loader: false,
				authUser: action.payload
			}
		}
		case SIGNIN_FACEBOOK_USER_SUCCESS: {
			return {
				...state,
				loader: false,
				authUser: action.payload
			}
		}
		case SIGNIN_TWITTER_USER_SUCCESS: {
			return {
				...state,
				loader: false,
				authUser: action.payload
			}
		}
		case SIGNIN_GITHUB_USER_SUCCESS: {
			return {
				...state,
				loader: false,
				authUser: action.payload
			}
		}
		case ON_SHOW_LOADER: {
			return {
				...state,
				loader: true
			}
		}
		case ON_HIDE_LOADER: {
			return {
				...state,
				loader: false
			}
		}
		case USER_SIGNUP_ERROR: {
			return {
				...state,
				loader: false,
				alertType:'error',
				alertMessage:action.payload,
				showMessage:true,
				meesage:action.payload
			}
		}
		case APP_SEND_RECOVERY: {
			return {
				...state,
				loader: true
			}
		}
		case APP_RECOVERY_SUCCESS: {
			return {
				...state,
				loader: false,
				showPasswordMessage:true,
				alertMessage: action.payload
			}
		}
		case HIDE_RECOVERY_MESSAGE: {
			return {
				...state,
				showPasswordMessage:false,
			}
		}
		case APP_CHANGE_PASSWORD: {
			return {
				...state,
				loader: true
			}
		}
		case APP_CHANGE_PASSWORD_SUCCESS: {
			return {
				...state,
				loader: false,
			}
		}
		case APP_UPLOAD_PHOTO: {
			return {
				...state,
				loader:true
			}
		}
		case APP_UPLOAD_PHOTO_SUCCESS: {
			return {
				...state,
				loader:false
			}
		}
		default:
			return state;
	}
}
