import {
    AUTOCOMPLETE_SEARCH_DATA,
	AUTOCOMPLETE_DATA_LOADED,
	AUTOCOMPLETE_SEARCH_CARTA,
} from 'constants/ActionTypes';

export const loadAutoCompleteData = (data,url,id,callback) => {
    return {
        type: AUTOCOMPLETE_SEARCH_DATA,
        payload: {data,url,id,callback}
    };
};
export const autoCompleteDataLoaded = (data) => {
    return {
        type: AUTOCOMPLETE_DATA_LOADED,
        payload: data
    };
};

export const loadAutoCompleteCarta = (data,url,id,callback) => {
    return {
        type: AUTOCOMPLETE_SEARCH_CARTA,
        payload: {data,url,id,callback}
    };
};