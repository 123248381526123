import {all, call, fork, put, takeEvery} from "redux-saga/effects";

import Api from "util/Api";
import {contactSaved} from "../actions/Contact";
import {handleRequestException} from "../actions/App";
import { SUBMIT_CONTACT_FORM } from "../constants/ActionTypes";


const contactSubmitFnRequest = async (payload) =>
    await  Api.post("contact",payload)
        .then(authUser => authUser)
        .catch(error => error);


export function* contactSubmit() {
    yield takeEvery(SUBMIT_CONTACT_FORM, function*({payload, origen}) {
        try {
            const response = yield call(contactSubmitFnRequest,payload);
            if (response.response.status === 200) {
                yield put(contactSaved(response));
            } else {
                yield [
                    put(contactSaved(response)),
                    put(handleRequestException(response, origen))
                ];
            }
        } catch (error) {
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(contactSubmit)
    ]);
}