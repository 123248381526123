import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import Api from "util/Api";

import {
	PROFILE_PAGOS_REQUEST,
	PROFILE_RESERVAS_REQUEST,
	PROFILE_RESERVA_REQUEST,
	PROFILE_IMPRIMIR_LIQUIDACION_REQUEST,
	PROFILE_IMPRIMIR_VOUCHER_REQUEST,
	PROFILE_CANCELAR_RESERVA_REQUEST,
	PROFILE_REACTIVAR_RESERVA_REQUEST,
	PROFILE_GUARDAR_PASAJEROS_REQUEST,
	PROFILE_INICIAR_TRANSACCION_REQUEST,
	PROFILE_EXTRACT_REQUEST,
	PROFILE_GARANTIZAR_RESERVA_REQUEST,
} from 'constants/ActionTypes';
import {
	pagosData,
	reservasData,
	reservaData,
	imprimirLiquidacionData,
	imprimirVoucherData,
	cancelarReservaData,
	reactivarReservaData,
	guardarPasajerosData,
	extractData,
	garantizarReservaData,
} from "../actions/Profile";

// Listado de reservas
const apiReservasRequest = async (payload) =>
	await  Api.get("profile/reservas", payload)
	.catch(error => error);

export function* catchReservasRequest() {
	yield takeEvery(PROFILE_RESERVAS_REQUEST, function*({payload, callback}) {
		try {
			const response = yield call(apiReservasRequest, payload);
			if (response.response.status === 200) {
				callback.call(null,response);
				yield put(reservasData(response.items));
			} else {
				// alert(response.response.status)
			}
		} catch (error) {
		}
	});
}

const apiPagosRequest = async (payload) => {
	return (
		await  Api.get("profile/pagos", payload)
		.catch(error => error)
	)
}

export function* catchPagosRequest() {
	yield takeEvery(PROFILE_PAGOS_REQUEST, function*({payload, callback}) {
		try {
			const response = yield call(apiPagosRequest, payload);
			if (response.response.status === 200) {
				callback.call(null,response);
				yield put(pagosData(response.items));
			} else {
				// alert(response.response.status);
			}
		} catch (error) {
		}
	});
}

const apiReservaRequest = async (payload) =>
	await  Api.get("profile/reserva", payload)
	.catch(error => error);

export function* catchReservaRequest() {
	yield takeEvery(PROFILE_RESERVA_REQUEST, function*({payload, callback}) {
		try {
			const response = yield call(apiReservaRequest, payload);
			if (response.response.status === 200) {
				callback.call(null,response);
				yield put(reservaData(response.items));
			} else {
				// alert(response.response.status)
			}
		} catch (error) {
		}
	});
}

const apiImprimirLiquidacionRequest = async (payload) =>
	await  Api.get("informes/reserva-voucher-liquidacion/"+payload.tipoMoneda, payload)
	.catch(error => error);

export function* catchImprimirLiquidacionRequest() {
	yield takeEvery(PROFILE_IMPRIMIR_LIQUIDACION_REQUEST, function*({payload, callback}) {
		try {
			const response = yield call(apiImprimirLiquidacionRequest, payload);
			if (response.response.status === 200) {
				callback.call(null,response);
				yield put(imprimirLiquidacionData(response.items));
			} else {
				// alert(response.response.status)
			}
		} catch (error) {
		}
	});
}

const apiImprimirVoucherRequest = async (payload) =>
	await  Api.get("informes/reserva-voucher-mayorista", payload)
	.catch(error => error);

export function* catchImprimirVoucherRequest() {
	yield takeEvery(PROFILE_IMPRIMIR_VOUCHER_REQUEST, function*({payload, callback}) {
		try {
			const response = yield call(apiImprimirVoucherRequest, payload);
			if (response.response.status === 200) {
				callback.call(null,response);
				yield put(imprimirVoucherData(response.items));
			} else {
				// alert(response.response.status)
			}
		} catch (error) {
		}
	});
}

const apiCancelarReservaRequest = async (payload) =>
	await  Api.put("profile/cancelar-reserva", payload)
	.catch(error => error);

export function* catchCancelarReservaRequest() {
	yield takeEvery(PROFILE_CANCELAR_RESERVA_REQUEST, function*({payload, callback}) {
		try {
			const response = yield call(apiCancelarReservaRequest, payload);
			if (response.response.status === 200) {
				callback.call(null,response);
				yield put(cancelarReservaData(response.items));
			} else {
				// alert(response.response.status)
			}
		} catch (error) {
		}
	});
}

const apiReactivarReservaRequest = async (payload) =>
	await  Api.put("profile/reactivar-reserva", payload)
	.catch(error => error);

export function* catchReactivarReservaRequest() {
	yield takeEvery(PROFILE_REACTIVAR_RESERVA_REQUEST, function*({payload, callback}) {
		try {
			const response = yield call(apiReactivarReservaRequest, payload);
			if (response.response.status === 200) {
				callback.call(null,response);
				yield put(reactivarReservaData(response.items));
			} else {
				// alert(response.response.status)
			}
		} catch (error) {
		}
	});
}

const apiGuardarPasajerosRequest = async (payload) =>
	await  Api.post("reserva/pasajeros", payload)
	.catch(error => error);

export function* catchGuardarPasajerosRequest() {
	yield takeEvery(PROFILE_GUARDAR_PASAJEROS_REQUEST, function*({payload, callback}) {
		try {
			const response = yield call(apiGuardarPasajerosRequest, payload);
			if (response.response.status === 200) {
				callback.call(null,response);
				yield put(guardarPasajerosData(response.items));
			} else {
				// alert(response.response.status)
			}
		} catch (error) {
		}
	});
}

const apiIniciarTransaccionRequest = async (payload) =>
	await  Api.post("reserva/pagar", payload)
	.catch(error => error);

export function* catchIniciarTransaccionRequest() {
	yield takeEvery(PROFILE_INICIAR_TRANSACCION_REQUEST, function*({payload, callback}) {
		try {
			const response = yield call(apiIniciarTransaccionRequest, payload);
			callback.call(null,response);
		} catch (error) {
		}
	});
}

// Extracto cliente
const apiExtractRequest = async (payload) =>
	await  Api.get("profile/extract", payload)
	.catch(error => error);

export function* catchExtractRequest() {
	yield takeEvery(PROFILE_EXTRACT_REQUEST, function*({payload, callback}) {
		try {
			const response = yield call(apiExtractRequest, payload);
			if (response.response.status === 200) {
				callback.call(null,response.cliente);
				yield put(extractData(response.cliente));
			} else {
				// alert(response.response.status)
			}
		} catch (error) {
		}
	});
}

const apiGarantizarReservaRequest = async (payload) =>
	await  Api.post("reserva/garantizar", payload)
	.catch(error => error);

export function* catchGarantizarReservaRequest() {
	yield takeEvery(PROFILE_GARANTIZAR_RESERVA_REQUEST, function*({payload, callback}) {
		try {
			const response = yield call(apiGarantizarReservaRequest, payload);
			if (response.response.status === 200) {
				callback.call(null,response);
				yield put(garantizarReservaData(response.items));
			} else {
				// alert(response.response.status)
			}
		} catch (error) {
		}
	});
}

export default function* rootSaga() {
	yield all([
		fork(catchReservasRequest),
		fork(catchPagosRequest),
		fork(catchReservaRequest),
		fork(catchImprimirLiquidacionRequest),
		fork(catchImprimirVoucherRequest),
		fork(catchCancelarReservaRequest),
		fork(catchGuardarPasajerosRequest),
		fork(catchIniciarTransaccionRequest),
		fork(catchReactivarReservaRequest),
		fork(catchExtractRequest),
		fork(catchGarantizarReservaRequest),
	]);
}