import React from 'react';
import {Link} from 'react-router-dom'
import {connect} from 'react-redux';
// import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import Alert from 'components/Alert';
import BackgroundComponent from 'containers/BackgroundComponent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import {
	hideMessage,
	showAuthLoader,
	userFacebookSignIn,
	userGithubSignIn,
	userGoogleSignIn,
	userSignIn,
	userTwitterSignIn
} from 'actions/Auth';
import {disabledButtonSearch} from 'actions/App';

class SignIn extends React.Component {
	constructor() {
		super();
		this.state = {
			email: '',
			password: '',
			newSiteMessage: '',
			showNewSiteMessages: false
		}
	}

	componentDidMount() {
		this.props.disabledButtonSearch(false);
		localStorage.removeItem("changeTipoViaje");
	}

	componentDidUpdate() {
		if (this.props.showMessage) {

		}
		if (this.props.logged && (this.props.authUser && this.props.authUser.invitado !== 1) ) {
			this.props.history.push('/');
		}
	}

	render() {
		const {
			email,
			password
		} = this.state;
		const {showMessage, loader, alertType, alertMessage, match} = this.props;

		const newSiteMessage =  '<br><br> En los últimos días hicimos actualizaciones en nuestro sitio, por lo tanto, es necesario que cambies tu contraseña. <br> Por favor ingresa a:  ';

		const limitDate = new Date() < new Date('2020-04-16');

		return (
			<BackgroundComponent>
				<div style={{height:'100vh'}}
					className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
					<div className="app-login-main-content">

						<div className="app-logo-content d-flex align-items-center justify-content-center">
							<Link className="logo-lg" to="/" title="Jambo">
								<img src={process.env.REACT_APP_LOGO} alt="img" title="img"/>
							</Link>
						</div>

						<div className="app-login-content">
							<div className="app-login-header mb-4">
								<h1><IntlMessages id="appModule.signin"/></h1>
							</div>

							<div className="app-login-form">
								<ValidatorForm
									ref="form"
									onSubmit={() => {
										this.props.showAuthLoader();
										this.props.userSignIn({email, password},function(){

										});
									}}
								>
									<fieldset>
										<TextValidator
											label={<IntlMessages id="appModule.email"/>}
											fullWidth
											onChange={(event) => this.setState({email: event.target.value})}
											value={email}
											margin="normal"
											variant={'outlined'}
											className="mt-1 my-sm-3"
											validators={['required','isEmail']}
											errorMessages={[<IntlMessages id="app.camporequerido" />,<IntlMessages id="app.emaillinvalido" />]}
										/>
										<TextValidator
											type="password"
											label={<IntlMessages id="appModule.password"/>}
											fullWidth
											onChange={(event) => this.setState({password: event.target.value})}
											value={password}
											variant={'outlined'}
											margin="normal"
											className="mt-1 my-sm-3"
											validators={['required']}
											errorMessages={[<IntlMessages id="app.camporequerido" />]}
										/>

										<div className="mb-3 d-flex align-items-center justify-content-between">
											<Button
												variant="contained"
												type="submit"
												color="primary"
											>
												<IntlMessages id="appModule.signIn"/>
											</Button>
											<div>
												<Link to="/signup">
													<IntlMessages id="appModule.signup"/>
												</Link>
												&nbsp;|&nbsp;
												<Link to="/home">
													<IntlMessages id="pages.home"/>
												</Link>
											</div>
										</div>

										<div className="app-social-block my-1 my-sm-3">
											<Link to="/forgotpassword">
												<IntlMessages id="signIn.forgotpassword"/>
											</Link>
										</div>

									</fieldset>
								</ValidatorForm>
							</div>
						</div>

					</div>
					{
						loader &&
						<div className="loader-view">
							<CircularProgress/>
						</div>
					}
					{showMessage && <Alert {...this.props} type={alertType} content={limitDate && match.url === '/signin' ? alertMessage + newSiteMessage : alertMessage} addButton={limitDate} title='notification.error' />}

				</div>
			</BackgroundComponent>
		);
	}
}

const mapStateToProps = ({auth}) => {
	const {loader, alertMessage, showMessage, authUser} = auth;
	return {loader, alertMessage, showMessage, authUser}
};

export default connect(mapStateToProps, {
	userSignIn,
	hideMessage,
	showAuthLoader,
	userFacebookSignIn,
	userGoogleSignIn,
	userGithubSignIn,
	userTwitterSignIn,
	disabledButtonSearch
})(SignIn);
