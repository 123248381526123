import React from 'react';
import {Link,withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Confirm from 'components/Confirm';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import {Dropdown, DropdownMenu, DropdownToggle} from 'reactstrap';
import {
	BELOW_THE_HEADER,
	COLLAPSED_DRAWER,
	FIXED_DRAWER,
	HORIZONTAL_NAVIGATION,
	INSIDE_THE_HEADER
} from 'constants/ActionTypes';
import CartList from '../CartList/index';
import CardHeader from 'components/dashboard/Common/CardHeader/index';
import {switchLanguage, toggleCollapsedNav,switchCurrency,getLocalCurrencie} from 'actions/Setting';
import IntlMessages from 'util/IntlMessages';
import LanguageSwitcher from 'components/LanguageSwitcher/index';
import Menu from 'components/TopNav/Menu';
import UserInfoPopup from 'components/UserInfo/UserInfoPopup';
import CardActions from '@material-ui/core/CardActions';
import {toggleCart,hideCart,clearCart,editModeOff,removeFromCart, saveCotizacion} from 'actions/Booking';
import Badge from '@material-ui/core/Badge';
import {Alert} from 'reactstrap';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Lodash from "lodash";
import Login from 'components/Login/index';
import ActiveNotification from 'components/ActiveNotification/index';
import { loadFeaturedPages } from '../../actions/Page';
import {openModalLogin,closeModalLogin} from 'actions/App';
import moment from 'moment';
import {Redirect} from 'react-router-dom';

import './index.css';

class Header extends React.Component {

oncurrencySelect = () => {
	this.setState({
		currency: !this.state.currency
	})
};
onCartListSelect = () => {
	this.setState({
		cartList: !this.state.cartList
	})
};
onLangSwitcherSelect = (event) => {
	this.setState({
		langSwitcher: !this.state.langSwitcher, anchorEl: event.currentTarget
	})
};
onSearchBoxSelect = () => {
	this.setState({
		searchBox: !this.state.searchBox
	})
};
onAppsSelect = () => {
	this.setState({
		apps: !this.state.apps
	})
};
onUserInfoSelect = () => {
	this.setState({
		userInfo: !this.state.userInfo
	})
};
handleRequestClose = () => {
	this.setState({
		langSwitcher: false,
		userInfo: false,
		cartList: false,
		currency: false,
		lang: null,
		searchBox: false,
		apps: false
	});
};
onToggleCollapsedNav = (e) => {
	const val = !this.props.navCollapsed;
	this.props.toggleCollapsedNav(val);
};

state = {
	anchorEl: undefined,
	searchBox: false,
	searchText: '',
	// cartList: false,
	userInfo: false,
	currency: false,
	loginItem: false,
	modalVisible: false,
	langSwitcher: false,
	changeCoint: undefined,
	modalClearVisible: false,
	modalCotizacionVisible: false,
	modalLanguageVisible: false,
	modalClearCardLangSwitcher: false,
}

componentDidMount=()=>{
	let defaultMoneda = Lodash.get(this.props, 'authUser.moneda.simbolo', this.props.localCurrencie);
	if(this.props.cart){
		this.props.cart.forEach(v=>{
			if(v.tipo === 'acomodacion'){
				if(v.form.divisa !== defaultMoneda){
					this.props.clearCart();
				}
			}else{
				if(v.moneda !== defaultMoneda){
					this.props.clearCart();
				}
			}
		});
	}
}

showConfirm = (curr)=>{
	if(this.props.cart.length > 0){
		this.setState({modalVisible: true, changeCoint: curr}, ()=>{});
	}else{
		let url = window.location.href.split('?');
		this.handleRequestClose();
		localStorage.setItem('localCurrencieStorage', curr.simbolo);
		this.props.getLocalCurrencie(curr.simbolo);
		if(url.length>1){
			this.props.history.push('?'+url[1]);
		}else{
			this.props.history.push();
		}
	}
}

clearCartConfirm = () => {
	this.setState({modalClearVisible: true}, ()=>{});
}

showConfirmCotizacion = () => {
	this.props.hideCart();
	this.setState((state) => ({ ...state, modalCotizarVisible: true}));
}

hideConfirmCotizacion = (btn) => {
	this.setState((state) => ({ ...state, modalCotizarVisible: false}));

	if (btn === true) {
		this.onConfirmSaveCotizacion();
	}
}

getEntradaSalidaReservaDates = (cart) => {
	const {entrada, salida} = cart.reduce((accum, current) => {
		const {entrada, salida} = accum;

		const currentDesde = current.tipo === 'acomodacion' ? current.form.fecha_ini : current.form.fecha_desde;
		const currentHasta = current.tipo === 'acomodacion' ? current.form.fecha_fin : current.form.fecha_desde;
		const currentDesdeMoment = moment.isMoment(currentDesde) ? currentDesde : moment(currentDesde);
		const currentHastaMoment = moment.isMoment(currentHasta) ? currentHasta : moment(currentHasta);
		const minDate = entrada && (entrada < currentDesdeMoment) ? entrada : currentDesdeMoment;
		const maxDate = salida && (salida > currentHastaMoment) ? salida : currentHastaMoment;

		return {
			entrada: minDate,
			salida: maxDate,
		}

	}, {entrada: null, salida: null});

	return {
		entrada: entrada.format('YYYY-MM-DD'),
		salida: salida.format('YYYY-MM-DD'),
	};
}

getMaxNumPax = (cart, hasServices) => {
	let maxNumAdultos = hasServices ? 1 : 0;
	let maxNumMenores = 0;

	cart.forEach((v,i)=>{
		const adultos = parseInt(v.form.adultos);
		const menores = parseInt(v.form.menores);

		maxNumMenores = (maxNumMenores < menores) ? menores : maxNumMenores;

		if(hasServices) {
			maxNumAdultos = (maxNumAdultos < adultos) ? adultos : maxNumAdultos;
		} else {
			maxNumAdultos += adultos * v.form.cantidad;
		}
	});

	return {adultos: maxNumAdultos, menores: maxNumMenores};
}

createPasajeros = (cantidad = 0, tipo = 'adultos') => {
	return Array(cantidad).fill().map((pax, idx) => {
		return {
			nombre:'',
			edad:'',
			tipo: tipo === 'menores' ? 1 : 0,
			pasaporte:'',
			titular: idx === 0 ? true : false,
		}
	});
}

onConfirmSaveCotizacion = ()=>{
	const defaultMoneda = localStorage.getItem('tempCurrency') || Lodash.get(this.props, 'authUser.moneda.simbolo', this.props.localCurrencie);
	let tipoViaje = 1; // siempre si para los colombianos

	if(defaultMoneda !== "COP"){
		tipoViaje = this.props.tipoViaje?this.props.tipoViaje:0;
	}

	const { entrada, salida } = this.getEntradaSalidaReservaDates(this.props.cart)

	const hasServices = this.props.cart.some(item => item.id_servicio && item.price);
	const {adultos, menores} = this.getMaxNumPax(this.props.cart, hasServices);
	const adultosData = this.createPasajeros(adultos);
	const menoresData = this.createPasajeros(menores, 'menores');
	const pasajeros = [ ...adultosData, ...menoresData ];

	let reserva = {
		id: null,
		id_servicios_eliminados: '',
		id_acomodaciones_eliminados: '',
		destinos: [],
		simbolo_moneda: defaultMoneda,
		entrada,
		salida,
		adultos: adultosData.length,
		menores: menoresData.length,
		tipo_viaje: tipoViaje,
		observacion: '',

		// forma de pago
		forma_pago: 1,
		on_request: true
	};

	const servicios = [];
	const acomodaciones = [];

	this.props.cart.forEach((v, i) => {
		if(v.price && v.price.info){ // mode edit: info doesnt exist if the service is being edited
			servicios.push({
				info: v.price.info,
				vuelo: v.form.vuelo,
			});
		}

		if(v.hotel && v.hotel.info){
			reserva.on_request = true;
			reserva.afectaDisponibilidad = false;

			acomodaciones.push({
				id: v.hotel.id,
				nombre_acomodacion: v.nombre_acomodacion,
				info: v.hotel.info,
				form: v.form,
				on_request: true,
				afectaDisponibilidad: false
			});
		}

		reserva.destinos.push(parseInt(v.id_destino));
	});

	// unique
	reserva.destinos = reserva.destinos.filter((v, i, a) => a.indexOf(v) === i);

	// join
	reserva.destinos = reserva.destinos.join();

	const titularesAcomodacion = acomodaciones.map((acomodacion, idx) => {
		return { id: idx, value: '-' };
	});

	// Guardar cotización
	this.props.saveCotizacion({
		cotizar: true,
		reserva: reserva,
		pasajeros: pasajeros,
		servicios: servicios,
		acomodaciones: acomodaciones,
		titularesAcomodacion: titularesAcomodacion,
		tipoViaje: tipoViaje
	});
}

hideConfirm = (btn)=>{
	if(this.state.modalVisible){
		this.setState({modalVisible: false});
		if(btn===true){
			this.props.cart.forEach(item=>{
				this.props.removeFromCart(item.uid);
			})
		}
		this.handleRequestClose();
		localStorage.setItem('localCurrencieStorage', this.state.changeCoint.simbolo);
		this.props.getLocalCurrencie(this.state.changeCoint.simbolo);
		this.props.history.push();
		// this.props.switchCurrency(this.state.changeCoint);
	}else if(this.state.modalClearVisible){
		this.setState({modalClearVisible: false});
		if(btn===true){
			this.props.clearCart();
		}
	}
	else if(this.state.modalLanguageVisible){
		this.setState({modalLanguageVisible: false});
		if(btn===true){
			this.props.clearCart();
			this.props.switchLanguage(this.state.lang);
			this.props.loadFeaturedPages();
		}
	}
	else if(this.state.modalClearCardLangSwitcher){
		this.setState({modalClearCardLangSwitcher: false});
		if(btn===true){
			this.props.clearCart();
			this.props.switchLanguage(this.state.lang);
			this.props.loadFeaturedPages();
		}
	}

}

updateSearchText(evt) {
	this.setState({
		searchText: evt.target.value,
	});
}

redirectToSignin = () => {
	if(this.props.authUser){
		if(this.props.authUser.invitado===1){
			this.props.openModalLogin();
		}else{
			this.props.history.push('/signin');
		}
	}else{
		this.props.history.push('/signin');
	}
}

showConfirmLanguage = (locale)=>{
	if(this.props.cart.length > 0){
		// this.setState({modalVisible: true, changeCoint: curr}, ()=>{});
		this.setState({modalClearCardLangSwitcher: true, lang: locale}, ()=>{});
		// this.setState({
		// 	modalLanguageVisible:true
		// })
	}else{
		this.props.switchLanguage(locale);
		this.props.loadFeaturedPages();
	}
}

hideLogin=()=>{
	this.props.closeModalLogin();
}

seguirComprando = ()=> {
	this.props.hideCart();
	if(this.props.history.location.pathname !== "/booking" && this.props.history.location.pathname !== "/hotels") {
		this.props.history.push("booking");
	}
}

render() {
	const {drawerType, locale, navigationStyle, horizontalNavPosition,authUser,showCart, cart,currencies,localCurrencie, responseSavedCotizacion} = this.props;
	const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-block d-xl-none' : drawerType.includes(COLLAPSED_DRAWER) ? 'd-block' : 'd-none';
	let granTotal = 0;
	let defaultMoneda = Lodash.get(this.props, 'authUser.moneda.simbolo', localCurrencie);
	let total_items = cart.length;
	let intViewportWidth = window.innerWidth < 398;

	cart.forEach(element => {
		if(element.tipo==='acomodacion'){
			granTotal += element.trm_divisa ? Math.ceil(parseFloat(element.precio) / element.trm_divisa) : parseFloat(element.precio);
		}else{
			granTotal += element.trm_divisa ? Math.ceil(parseFloat(element.price.total_venta) / element.trm_divisa) : parseFloat(element.price.total_venta);
		}
	});
	let valor_item = parseFloat(granTotal).toLocaleString('en-EN', { style: 'currency', currency: defaultMoneda?defaultMoneda:localCurrencie, currencyDisplay:'code', minimumFractionDigits: 0 });


	return (
	<AppBar className={`app-main-header ${(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) ? 'app-main-header-top' : ''}`}>
		
		

		<ActiveNotification />
		{
			responseSavedCotizacion && responseSavedCotizacion.success &&
				<Redirect push to={"/profile/reserva/" + responseSavedCotizacion.reserva} />
		}
		<Login showLogin={this.props.showModalLogin} hideLogin={this.hideLogin} />
		<Toolbar className="app-toolbar" disableGutters={false}>
			{navigationStyle === HORIZONTAL_NAVIGATION ?
				<div className="d-block d-md-none pointer mr-3" onClick={this.onToggleCollapsedNav}>
					<span className="jr-menu-icon">
						<span className="menu-icon"/>
					</span>
				</div>
				:
				<IconButton className={`jr-menu-icon mr-3 ${drawerStyle}`} aria-label="Menu" onClick={this.onToggleCollapsedNav}>
					<span className="menu-icon"/>
				</IconButton>
			}

			<Link className="app-logo mr-2 d-none d-sm-block" to="/">
				<img src={process.env.REACT_APP_LOGO} alt="img" title="img"/>
			</Link>


			{(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === INSIDE_THE_HEADER) && <Menu/>}

			<ul className="header-notifications list-inline ml-auto">
				{authUser &&
					<li className="list-inline-item ">
					<Dropdown className="quick-menu" isOpen={this.state.currency} toggle={this.oncurrencySelect.bind(this)}>

						<Confirm {...this.props} open={this.state.modalClearVisible} hideConfirm={this.hideConfirm} title={'app.confirm'} content={'app.confirmTextDeleteFromCart'} />
						<Confirm {...this.props} open={this.state.modalVisible} hideConfirm={this.hideConfirm} title={'app.confirm'} content={'app.emptyCar'} />
						<Confirm {...this.props} open={this.state.modalClearCardLangSwitcher} hideConfirm={this.hideConfirm} title={'app.confirm'} content={'app.emptyCarLang'} />

						<DropdownToggle className="d-inline-block" tag="span" data-toggle="dropdown" disabled={authUser.invitado===1?false:true}>
							<Button  className="text-white" disabled={authUser.invitado===1?false:true}>
								{defaultMoneda}
							</Button>
						</DropdownToggle>

						<DropdownMenu right className="w-50">
							<ul className="header-notifications list-inline ml-auto">
								{currencies && currencies.map(curr=>{
									return (<li
									key={curr.id}
									className="pointer mb-1 bb-1"
									onClick={() => this.showConfirm(curr)}>
										<div style={{borderBottom:'1px solid #ddd'}}>
											{/* <i className={`flag flag-24 flag-${icon}`}/> */}
											<h4 className="mb-0">{curr.simbolo}</h4>
											<small>{curr.nombre}</small>
										</div>
									</li>);
								})}
							</ul>

						</DropdownMenu>
					</Dropdown>
				</li>
				}
				<li className="list-inline-item">
					<Dropdown className="quick-menu" isOpen={this.state.langSwitcher} toggle={this.onLangSwitcherSelect.bind(this)}>
						<DropdownToggle className="d-inline-block" tag="span" data-toggle="dropdown">
							<IconButton className="icon-btn">
								<i className={`flag flag-24 flag-${locale.icon}`}/>
							</IconButton>
						</DropdownToggle>

						<DropdownMenu right className="w-50">
							<LanguageSwitcher switchLanguage={this.showConfirmLanguage} handleRequestClose={this.handleRequestClose}/>
						</DropdownMenu>
					</Dropdown>
				</li>
				<li className="list-inline-item mail-tour">
				<Dropdown inNavbar={intViewportWidth} addonType={intViewportWidth} className ="quick-menu" isOpen={showCart} toggle={this.props.toggleCart.bind(this)}>
					<DropdownToggle className="d-inline-block" tag="span" data-toggle="dropdown">
						<IconButton className="icon-btn">
							<Badge variant="dot" color="secondary" invisible={!this.props.editValue}>
								{
									total_items?
									<span style={{ position: 'absolute', backgroundColor: 'red', width: 19, height: 20, fontSize: 15, marginTop: -8, marginLeft: 15, borderRadius: 15 }}><b style={{ marginLeft: 1 }}>{total_items}</b></span>:
									null
								}
								<ShoppingCartIcon />
							</Badge>
						</IconButton>
					</DropdownToggle>


					<DropdownMenu right style={{minWidth:'300px',width:'40vw'}}>
						<CardHeader styleName="align-items-center" heading={<span><IntlMessages id="app.cart"/> ({total_items})</span>}/>

						{
							this.props.editValue?
							<Alert className="alert-addon-card bg-warning text-white shadow-lg px-6 py-1">
								<span className="icon-addon alert-addon">
									<i className="zmdi zmdi-notifications zmdi-hc-fw"/>
								</span>
								<span className="d-inline-block"><IntlMessages id='app.editandoReserva'/>: <b>{this.props.editValue.id}</b></span>
							</Alert>
							:
							null
						}

						<CartList cartItems={cart} />

						<CardActions style={{ display:"flex", flexFlow:"wrap", justifyContent:"space-between", margin: 0 }}>
							<Confirm open={!!this.state.modalCotizarVisible} hideConfirm={this.hideConfirmCotizacion} title={'app.confirm'} content={'app.confirmCotizacion'} />

							<div style={{fontSize: 18, marginTop: 5}}>
								<div style={{ marginRight: 10, float: 'left' }}><IntlMessages id='app.total'/>:</div> <span className="text-success">{valor_item}</span>
							</div>
								<div className="cart-actions-container cart-actions-direction">
									{
										total_items > 0 ?
										<>
											<Button className="mt-2 cart-btn" onClick={this.seguirComprando} variant="contained" color="primary" size={"small"}>
												<IntlMessages id="app.seguirComprando" />
											</Button>
											<Button onClick={this.clearCartConfirm} className="mt-2 cart-btn" color="primary">
												<IntlMessages id="app.limpiar" />
											</Button>
										</>
										: null
									}
									{this.props.editValue && <Button onClick={()=>this.props.editModeOff()} className="mt-2" color="secondary" variant='contained' size='small'>
										<IntlMessages id="app.cancelarEdicion" />
									</Button>}
									{
										total_items > 0 ?
										<>
											{
												!this.props.editValue &&
													<Button
														onClick={this.showConfirmCotizacion}
														className="mt-2 cart-btn"
														size={"small"}
														variant="outlined"
														color="primary"
													>
														<i className="zmdi zmdi-money mr-2 d-inline-block align-middle"></i>
														<IntlMessages id="app.cotizar" />
													</Button>
											}
											<Button
												className="mt-2 cart-btn cart-btn-reservar"
												variant="contained"
												color="primary"
												size={"small"}
												onClick={event=>{
													if(total_items === 0){
														this.props.hideCart();
														this.props.history.push("booking");
													}else{
														// this.setState({cartList:false});
														this.props.hideCart();
														this.props.history.push("/checkout");
													}
												}}
											>
												<i className={`zmdi zmdi-shopping-cart mr-2 d-inline-block align-middle`}/>
												<IntlMessages id="app.checkout" />
											</Button>
										</>
										: null
									}
							</div>
						</CardActions>
					</DropdownMenu>
				</Dropdown>
				</li>

				{navigationStyle === HORIZONTAL_NAVIGATION &&
				<li className="list-inline-item user-nav">
					<Dropdown className="quick-menu" isOpen={this.state.userInfo} toggle={this.onUserInfoSelect.bind(this)}>
						{authUser && (authUser && !authUser.invitado)?
							<DropdownToggle className="d-inline-block" tag="span" data-toggle="dropdown">
								{/* <IconButton>ergerg</IconButton> */}
								<Button className="text-white"><i className="zmdi zmdi-face zmdi-hc-fw mr-1"/>{this.props.authUser.nombre.split(" ")[0]}</Button>
							</DropdownToggle>
							:
							""
						}
						{authUser && (authUser && !authUser.invitado)?
							<DropdownMenu style={{width: 'auto'}} right>
								<UserInfoPopup {...this.props} />
							</DropdownMenu>
							:authUser && (authUser && authUser.invitado) ?
							<Button
								variant="contained"
								className={"col-12"}
								size={"large"}
								onClick={this.redirectToSignin}
								color="primary"
							>
								<IntlMessages id="appModule.signin"/>
							</Button>
							:
							<Button
								variant="contained"
								className={"col-12"}
								size={"large"}
								onClick={this.redirectToSignin}
								color="primary"
							>
								<IntlMessages id="appModule.signin"/>
							</Button>
						}
					</Dropdown>
				</li>}
			</ul>

			<div className="ellipse-shape"></div>
		</Toolbar>
	</AppBar>
	);
}

}

const mapStateToProps = state=>({
	drawerType: state.settings.drawerType,
	locale: state.settings.locale,
	navigationStyle: state.settings.navigationStyle,
	horizontalNavPosition: state.settings.horizontalNavPosition,
	currency: state.settings.currency,
	currencies: state.settings.currencies,

	showCart: state.booking.showCart,
	cart: state.booking.cart,
	editValue: state.booking.editValue,

	localCurrencie: state.settings.localCurrencie,

	authUser: state.auth.authUser,
	showModalLogin:state.app.showModalLogin,

	loadingCotizacion: state.booking.loadingCotizacion,
	responseSavedCotizacion: state.booking.responseSavedCotizacion
});

export default withRouter(connect(mapStateToProps, {
	toggleCollapsedNav,
	switchLanguage,
	switchCurrency,
	toggleCart,
	hideCart,
	clearCart,
	editModeOff,
	removeFromCart,
	openModalLogin,
	closeModalLogin,
	getLocalCurrencie,
	loadFeaturedPages,
	saveCotizacion
})(Header));
