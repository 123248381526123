import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'
import Settings from './Settings';
import Auth from './Auth';
import Blog from './Blog';
import AutoComplete from './AutoComplete';
import mice from './Mice';
import app from './App';
import contact from './Contact';
import workWithUs from './WorkWithUs';
import ipqrs from "./Ipqrs";
import booking from './Booking';
import destination from './Destination';
import page from './Page';
import profile from './Profile';
import operations from './Operations';
import paymentStatus from './PaymentStatus';
import hotelConfirm from './HotelConfirm';
import empresa from './Empresa';


export default (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  blog:Blog,
  AutoComplete,
  mice,
  app,
  contact,
  workWithUs,
  ipqrs,
  booking,
  destination,
  page,
  profile,
  operations,
  paymentStatus,
  hotelConfirm,
  empresa
});
