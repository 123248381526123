import {all, call, fork, put, takeEvery, takeLatest} from "redux-saga/effects";
import Api from "util/Api";

import {
	OPERATIONS_SERVICIOS_REQUEST,
	// OPERATIONS_SERVICIOS_LOADED,
	OPERATIONS_SERVICIO_DETALLE_REQUEST,
	// OPERATIONS_SERVICIO_DETALLE_LOADED,
	OPERATIONS_EXTRACTOS_REQUEST,
	// OPERATIONS_EXTRACTOS_LOADED,
	OPERATIONS_CHANGE_SERVICIO_STATE,
} from "constants/ActionTypes";
import {
	// serviciosRequest,
	serviciosLoaded,
	// servicioDetalleRequest,
	servicioDetalleLoaded,
	// extractosRequest,
	extractosLoaded,
	// changeServicioState,
} from "../actions/Operations";

// Listado de servicios
const apiServiciosRequest = async (payload) =>
	await  Api.get("proveedor/servicios", payload)
	.catch(error => error);

export function* catchServiciosRequest() {
	yield takeLatest(OPERATIONS_SERVICIOS_REQUEST, function*({payload,callback}) {
		try {
			const response = yield call(apiServiciosRequest, payload);
			if (response.response.status === 200) {
				callback.call(null,response);
				yield put(serviciosLoaded(response.items));
			} else {
				alert(response.response.status)
			}
		} catch (error) {
		}
	});
}

// Detalle de servicio
const apiServicioDetalleRequest = async (payload) =>
	await  Api.get("proveedor/servicio-detalle", payload)
	.catch(error => error);

export function* catchServicioDetalleRequest() {
	yield takeEvery(OPERATIONS_SERVICIO_DETALLE_REQUEST, function*({payload}) {
		try {
			const response = yield call(apiServicioDetalleRequest, payload);
			if (response.response.status === 200) {
				yield put(servicioDetalleLoaded(response.data));
			} else {
				alert(response.response.status)
			}
		} catch (error) {
		}
	});
}

// Listado de extractos
const apiExtractosRequest = async (payload) =>
	await  Api.get("proveedor/extracto", payload)
	.catch(error => error);

export function* catchExtractosRequest() {
	yield takeLatest(OPERATIONS_EXTRACTOS_REQUEST, function*({payload}) {
		try {
			const response = yield call(apiExtractosRequest, payload);
			if (response.response.status === 200) {
				yield put(extractosLoaded(response.data));
			} else {
				alert(response.response.status)
			}
		} catch (error) {
		}
	});
}

// Cambiar state servicio
const apiChangeServicioState = async (payload) =>
	await  Api.post("proveedor/servicio", payload)
	.catch(error => error);

export function* catchChangeServicioState() {
	yield takeEvery(OPERATIONS_CHANGE_SERVICIO_STATE, function*({payload, callback}) {
		try {
			const response = yield call(apiChangeServicioState, payload);
			if (response.response.status === 200) {
				callback.call(null,response);
				yield put(extractosLoaded(response.items));
			} else {
				alert(response.response.status)
			}
		} catch (error) {
		}
	});
}

export default function* rootSaga() {
	yield all([
		fork(catchServiciosRequest),
		fork(catchServicioDetalleRequest),
		fork(catchExtractosRequest),
		fork(catchChangeServicioState),
	]);
}