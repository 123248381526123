import {all, call, fork, put, takeEvery} from "redux-saga/effects";

import {
	auth,
	facebookAuthProvider,
	githubAuthProvider,
	googleAuthProvider,
	twitterAuthProvider
} from "../firebase/firebase";
import {
	SIGNIN_FACEBOOK_USER,
	SIGNIN_GITHUB_USER,
	SIGNIN_GOOGLE_USER,
	SIGNIN_TWITTER_USER,
	SIGNIN_USER,
	SIGNOUT_USER,
	SIGNUP_USER,
	GET_PROFILE_INFO,
	SEND_SIGNUP_DATA,
	APP_SEND_RECOVERY,
	SEND_SIGNUP_DIRECT_DATA,
	ACTIVE_NOTIFICATION,
} from "constants/ActionTypes";
import {
	showAuthMessage,
	userSignInSuccess,
	userSignOutSuccess,
	userSignUpSuccess,
	profileInfoLoaded,
	userSignUpError,
	appInternalError,
	sendPasswordRecoverySuccess,
	changePasswordSuccess,
	saveProfilePhotoSuccess,
	hideAuthLoader,
} from "actions/Auth";
import {
	closeModalLogin,
	AlertShowHide
} from "actions/App";
import {
	userFacebookSignInSuccess,
	userGithubSignInSuccess,
	userGoogleSignInSuccess,
	userTwitterSignInSuccess
} from "../actions/Auth";
import {
	switchLanguage,
	loadCurrencies,
	switchCurrency,
	setGlobalConfig,
	getLocalCurrencie
} from "../actions/Setting";
import {
	changeOrigen
} from "../actions/Booking";
import AuthHelper from "util/AuthHelper";
import { PRELOAD_USERDATA, APP_CHANGE_PASSWORD, APP_UPLOAD_PHOTO } from "../constants/ActionTypes";
import Api from "util/Api";
import languageData from 'components/LanguageSwitcher/data';


const createUserWithEmailPasswordRequest = async (email, password) =>
	await auth.createUserWithEmailAndPassword(email, password)
		.then(authUser => authUser)
		.catch(error => error);


const signInUserWithEmailPasswordRequest = async (email, password) =>
	await AuthHelper.login(email, password)
		.then(authUser => authUser)
		.catch(error => error);


const preloadUserDataFnRequest = async (token) =>
	await AuthHelper.getUserData(token)
		.then(authUser => authUser)
		.catch(error => error);

const sendSignUpDataFnRequest = async (data) =>
	await Api.post('registro',data)
		.then(authUser => authUser)
		.catch(error => error);

const sendSignUpDirectDataFnRequest = async (data) =>
	await Api.post('user/signup',data)
		.then(authUser => authUser)
		.catch(error => error);

const activeNofiticationRequest = async (data) =>
	await Api.post('suscripcion/agregar', data)
	.then(authUser => authUser)
	.catch(error => error);

const signOutRequest = async () =>
	await auth.signOut()
		.then(authUser => authUser)
		.catch(error => error);

const signInUserWithGoogleRequest = async () =>
	await auth.signInWithPopup(googleAuthProvider)
		.then(authUser => authUser)
		.catch(error => error);

const signInUserWithFacebookRequest = async () =>
	await auth.signInWithPopup(facebookAuthProvider)
		.then(authUser => authUser)
		.catch(error => error);

const signInUserWithGithubRequest = async () =>
	await auth.signInWithPopup(githubAuthProvider)
		.then(authUser => authUser)
		.catch(error => error);

const signInUserWithTwitterRequest = async () =>
	await auth.signInWithPopup(twitterAuthProvider)
		.then(authUser => authUser)
		.catch(error => error);

function* createUserWithEmailPassword({payload}) {
	const {email, password} = payload;
	try {
		const signUpUser = yield call(createUserWithEmailPasswordRequest, email, password);
		if (signUpUser.message) {
			yield put(showAuthMessage(signUpUser.message));
		} else {
			localStorage.setItem('user_id', signUpUser.user.uid);
			yield put(userSignUpSuccess(signUpUser.user.uid));
		}
	} catch (error) {
		yield put(showAuthMessage(error));
	}
}

function* signInUserWithGoogle() {
	try {
		const signUpUser = yield call(signInUserWithGoogleRequest);
		if (signUpUser.message) {
			yield put(showAuthMessage(signUpUser.message));
		} else {
			localStorage.setItem('user_id', signUpUser.user.uid);
			yield put(userGoogleSignInSuccess(signUpUser.user.uid));
		}
	} catch (error) {
		yield put(showAuthMessage(error));
	}
}


function* signInUserWithFacebook() {
	try {
		const signUpUser = yield call(signInUserWithFacebookRequest);
		if (signUpUser.message) {
			yield put(showAuthMessage(signUpUser.message));
		} else {
			localStorage.setItem('user_id', signUpUser.user.uid);
			yield put(userFacebookSignInSuccess(signUpUser.user.uid));
		}
	} catch (error) {
		yield put(showAuthMessage(error));
	}
}


function* signInUserWithGithub() {
	try {
		const signUpUser = yield call(signInUserWithGithubRequest);
		if (signUpUser.message) {
			yield put(showAuthMessage(signUpUser.message));
		} else {
			localStorage.setItem('user_id', signUpUser.user.uid);
			yield put(userGithubSignInSuccess(signUpUser.user.uid));
		}
	} catch (error) {
		yield put(showAuthMessage(error));
	}
}


function* signInUserWithTwitter() {
	try {
		const signUpUser = yield call(signInUserWithTwitterRequest);
		if (signUpUser.message) {
			if (signUpUser.message.length > 100) {
				yield put(showAuthMessage('Your request has been canceled.'));
			} else {
				yield put(showAuthMessage(signUpUser.message));
			}
		} else {
			localStorage.setItem('user_id', signUpUser.user.uid);
			yield put(userTwitterSignInSuccess(signUpUser.user.uid));
		}
	} catch (error) {
		yield put(showAuthMessage(error));
	}
}

function* signInUserWithEmailPassword({payload}) {
	const {email, password} = payload;
	try {
		const signInUser = yield call(signInUserWithEmailPasswordRequest, email, password);
		let cart = JSON.parse(localStorage.getItem("cart"));
		if (signInUser.response.status === 401) {
			yield put(showAuthMessage(signInUser.message));
		} else if(signInUser.response.status === 429) {
			yield put(showAuthMessage(signInUser.errors.email));
		} else if(signInUser.response.status === 200) {
			const user = signInUser.user;
			const currencies = signInUser.currencies;
			const simbolo = user.moneda ? user.moneda.simbolo : localStorage.getItem('localCurrencieStorage');
			let lang = languageData[0];
			localStorage.setItem('token_id', signInUser.access_token);

			// localStorage.setItem('localCurrencieStorage', simbolo);
			languageData.forEach((item)=>{
				if(item.locale === user.lang){lang = item}
			});
			yield put(closeModalLogin());
			yield put(switchLanguage(lang,true));
			yield put(loadCurrencies(currencies,true));
			yield put(switchCurrency(user.moneda,true));
			yield put(getLocalCurrencie(simbolo));
			yield put(userSignInSuccess(signInUser.access_token,signInUser.user));

			if (user.moneda.simbolo === 'COP') {
				yield put(changeOrigen(1));
			} else {
				const isExtranjero = user.id_tipo_usuario === 1 || user.id_tipo_usuario === 16;
				const defaultTipoViaje = isExtranjero ? 0 : 1;

				yield put(changeOrigen(localStorage.getItem('tipo_viaje') || defaultTipoViaje));
			}

			if(user.suscrito === 0 && !localStorage.getItem('suscrito')){
				yield put(AlertShowHide(true));
			}
			try {
				if(cart.moneda !== user.moneda){
					// yield put(clearCart());
				}
			}catch {

			}

		}
	} catch (error) {
		yield put(showAuthMessage(error));
	}
}

function* signOut() {
	try {
		const signOutUser = yield call(signOutRequest);
		if (signOutUser === undefined) {
			localStorage.removeItem('token_id');
			localStorage.removeItem('edit_extra');
			yield put(userSignOutSuccess(signOutUser));
		} else {
			yield put(showAuthMessage(signOutUser.message));
		}
	} catch (error) {
		yield put(showAuthMessage(error));
	}
}

function* preloadUserDataFn({payload,noSave}) {
	try {
		const preloadedUser = yield call(preloadUserDataFnRequest,payload);
		const user = preloadedUser.user;
		yield put(setGlobalConfig(preloadedUser.config));
		if (user === undefined) {
			// alert("Error cargando datos");
			// localStorage.removeItem('token_id');
			yield put(userSignOutSuccess(user));
		} else {
			const currencies = preloadedUser.currencies;
			const currencieLoad = preloadedUser.currency_code;
			localStorage.setItem('token_id', payload);
			let lang = languageData[0];
			languageData.forEach((item)=>{
				if(item.locale === user.lang){lang = item}
			});
			yield put(switchLanguage(lang,true));
			yield put(loadCurrencies(currencies,true));
			yield put(switchCurrency(user.moneda,true));
			yield put(userSignInSuccess(payload,user));
			if(user.suscrito === 0 && !localStorage.getItem('suscrito')){
				yield put(AlertShowHide(true));
			}
			//MONEDA LOCAL INVITADOS
			if(localStorage.getItem("localCurrencieStorage")){
				yield put(getLocalCurrencie(localStorage.getItem("localCurrencieStorage")));
			}else{
				localStorage.setItem('localCurrencieStorage', currencieLoad);
				yield put(getLocalCurrencie(currencieLoad));
			}
			if (user.moneda.simbolo === 'COP') {
				yield put(changeOrigen(1));
			} else {
				const isExtranjero = user.id_tipo_usuario === 1 || user.id_tipo_usuario === 16;
				const defaultTipoViaje = isExtranjero ? 0 : 1;

				yield put(changeOrigen(localStorage.getItem('tipo_viaje') || defaultTipoViaje));
			}
		}
	} catch (error) {
		// console.error(error);
		yield put(showAuthMessage(error));
	}
}

function* sendSignUpDataFn({payload,callback}) {
	try {
		const response = yield call(sendSignUpDataFnRequest,payload);
		if (response.response.status === 200) {
			callback.call(response.message);
			yield put(userSignUpSuccess({
				message:response.message
			}));
		} else if(response.response.status !== 500){
			let errors =[];
			for(let item in response.errors){
				errors.push(response.errors[item].join(","));
			}
			yield put(userSignUpError(errors.join("<br>")));
		}else{
			yield put(appInternalError(response));
		}
	} catch (error) {
		// console.error(error);
		yield put(showAuthMessage(error));
	}
}

function* activeNofiticationFn({payload}) {
	try {
		yield call(activeNofiticationRequest,payload);
	} catch (error) {
	}
}

function* sendSignUpDirectDataFn({payload}) {
	try {
		const response = yield call(sendSignUpDirectDataFnRequest,payload);
		if(response.response.status === 200){
			localStorage.setItem('token_id', response.access_token);
			const user = response.user;
			const currencies = response.currencies;


			let lang = languageData[0];
			languageData.forEach((item)=>{
				if(item.locale === user.lang){lang = item}
			});
			yield put(closeModalLogin());
			yield put(switchLanguage(lang,true));
			yield put(loadCurrencies(currencies,true));
			yield put(switchCurrency(user.moneda,true));
			yield put(userSignInSuccess(response.access_token,response.user));
		}else{
			let errors =[];
			for(let item in response.errors){
				errors.push(response.errors[item].join(","));
			}
			yield put(userSignUpError(errors.join("<br>")));
			yield put(hideAuthLoader());
		}
	} catch (error) {
	}
}

export function* createUserAccount() {
	yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInWithGoogle() {
	yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
}

export function* signInWithFacebook() {
	yield takeEvery(SIGNIN_FACEBOOK_USER, signInUserWithFacebook);
}

export function* signInWithTwitter() {
	yield takeEvery(SIGNIN_TWITTER_USER, signInUserWithTwitter);
}

export function* signInWithGithub() {
	yield takeEvery(SIGNIN_GITHUB_USER, signInUserWithGithub);
}

export function* signInUser() {
	yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
	yield takeEvery(SIGNOUT_USER, signOut);
}

export function* preloadUserData() {
	yield takeEvery(PRELOAD_USERDATA, preloadUserDataFn);
}


// Get profile ------------
const getProfileInfoFnRequest = async () =>
	await Api.get('perfil')
		.then(authUser => authUser)
		.catch(error => error);

export function* getProfileInfo() {
	yield takeEvery(GET_PROFILE_INFO, function* getProfileInfoFn({callback}) {
		try {
			const response = yield call(getProfileInfoFnRequest);
			if (!response.success) {
				yield put(userSignOutSuccess());
			} else {
				callback.call(null,response);
				yield put(profileInfoLoaded(response));
			}
		} catch (error) {
			// console.error(error);
			yield put(showAuthMessage(error));
		}
	});
}
// ------------------------

export function* sendSignUpData() {
	yield takeEvery(SEND_SIGNUP_DATA, sendSignUpDataFn);
}

export function* sendSignUpDirectData() {
	yield takeEvery(SEND_SIGNUP_DIRECT_DATA, sendSignUpDirectDataFn);
}

export function* activeNofiticationData() {
	yield takeEvery(ACTIVE_NOTIFICATION, activeNofiticationFn);
}

//RECUPERACIÓN DE CONTRASEÑA
const sendPasswordRecoveryRequest = async (payload) =>
	await Api.post('password-recovery',payload)
	.catch(error => error);

function* sendPasswordRecovery({payload}) {
	try {
		const response = yield call(sendPasswordRecoveryRequest,payload);

		yield put(sendPasswordRecoverySuccess(response));
	} catch (error) {
		yield put(sendPasswordRecoverySuccess(error));
	}
}
export function* sendPasswordRecoveryData() {
	yield takeEvery(APP_SEND_RECOVERY, sendPasswordRecovery);
}
//RECUPERACIÓN DE CONTRASEÑA

//CAMBIAR CONTRASEÑA
const changePasswordRequest = async (payload) =>{
	let url = 'change-password';
	if(payload.current){
		url = 'change-password-back';
	}
	return await Api.post(url,payload).catch(error => error);
}

function* changePassword({payload,callback}) {
	try {
		const response = yield call(changePasswordRequest,payload);

		yield put(changePasswordSuccess(payload));
		callback.call(null,response);
	} catch (error) {
	}
}
export function* changePasswordData() {
	yield takeEvery(APP_CHANGE_PASSWORD, changePassword);
}
//CAMBIAR CONTRASEÑA

//SUBIR FOTO
const uploadPhotoRequest = async (payload) =>{
	let url = 'change-photo';
	return await Api.post(url,payload).catch(error => error);
}

function* uploadPhoto({payload,callback}) {
	try {
		const response = yield call(uploadPhotoRequest,payload);
		yield put(saveProfilePhotoSuccess(payload));
		callback.call(null,response);
	} catch (error) {
	}
}
export function* uploadPhotoData() {
	yield takeEvery(APP_UPLOAD_PHOTO, uploadPhoto);
}
//END SUBIR FOTO

export default function* rootSaga() {
	yield all([fork(signInUser),
		fork(createUserAccount),
		fork(signInWithGoogle),
		fork(signInWithFacebook),
		fork(signInWithTwitter),
		fork(signInWithGithub),
		fork(signOutUser),
		fork(preloadUserData),
		fork(getProfileInfo),
		fork(sendSignUpData),
		fork(sendSignUpDirectData),
		fork(sendPasswordRecoveryData),
		fork(changePasswordData),
		fork(uploadPhotoData),
		fork(activeNofiticationData),
	]);
}