
import React from 'react';

export default class BackgroundComponent extends React.Component {
    render(){
        return <div style={{
            width:'100vw',
            height:'100vh',
            background:'url("http://i.imgur.com/HgflTDf.jpg") 100% fixed',
            backgroundSize:'cover'
        }}>
            <div style={{
                background: this.props.BackgroundColor?this.props.BackgroundColor:'rgba(4, 40, 68, 0.85)',
                width:'100vw',
                height:'100vh',
            }}>
                {this.props.children}
            </div>
        </div>
    }
}
