import {
	// DEST_LOADING,
	DEST_DESTINATION_REQUESTED,
	DEST_TARIFF_REQUESTED,
	// DEST_VIEW_MODE,
	DEST_DATA_LOADED,
} from 'constants/ActionTypes';

const INIT_STATE = {
	loading_destinations: false,
	loading_tariff: false,
	destinations: [],
	tariff: []
};


export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case DEST_TARIFF_REQUESTED:
		case DEST_DESTINATION_REQUESTED: {
			return {
				...state,
				['loading_'+action.origen]: true,
				destinations: [],
				tariff: []
			}
		}
		case DEST_DATA_LOADED:
		{
			return {
				...state,
				['loading_'+action.origen]: false,
				[action.origen]: action.payload,
			}
		}
		// case DEST_VIEW_MODE: {
		// 	return {
		// 		...state,
		// 		loading_destinations:action.mode,
		// 	}
		// }
		// case DEST_LOADING: {
		// 	return {
		// 		...state,
		// 		loading_destinations:action.loading,
		// 	}
		// }
		default:
			return state;
	}
}
