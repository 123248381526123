import {
	HIDE_MESSAGE,
	INIT_URL,
	ON_HIDE_LOADER,
	ON_SHOW_LOADER,
	SHOW_MESSAGE,
	SIGNIN_FACEBOOK_USER,
	SIGNIN_FACEBOOK_USER_SUCCESS,
	SIGNIN_GITHUB_USER,
	SIGNIN_GITHUB_USER_SUCCESS,
	SIGNIN_GOOGLE_USER,
	SIGNIN_GOOGLE_USER_SUCCESS,
	SIGNIN_TWITTER_USER,
	SIGNIN_TWITTER_USER_SUCCESS,
	SIGNIN_USER,
	SIGNIN_USER_SUCCESS,
	SIGNOUT_USER,
	SIGNOUT_USER_SUCCESS,
	SIGNUP_USER,
	SIGNUP_USER_SUCCESS,
	PRELOAD_USERDATA,
	GET_PROFILE_INFO,
	PROFILE_INFO_LOADED,
	SEND_SIGNUP_DATA,
	SEND_SIGNUP_DIRECT_DATA,
	USER_SIGNUP_ERROR,
	APP_INTERNAL_ERROR,
	APP_SEND_RECOVERY,
	APP_RECOVERY_SUCCESS,
	HIDE_RECOVERY_MESSAGE,
	APP_CHANGE_PASSWORD,
	APP_CHANGE_PASSWORD_SUCCESS,
	APP_UPLOAD_PHOTO,
	APP_UPLOAD_PHOTO_SUCCESS,
	ACTIVE_NOTIFICATION,
} from 'constants/ActionTypes';

export const preloadUserData = (token) => {
	return {
		type: PRELOAD_USERDATA,
		payload: token
	};
};
export const userSignUp = (user) => {
	return {
		type: SIGNUP_USER,
		payload: user
	};
};
export const userSignIn = (user,callback) => {
	return {
		type: SIGNIN_USER,
		payload: user,
		callback
	};
};
export const userSignOut = () => {
	return {
		type: SIGNOUT_USER
	};
};
export const activeNotification=(status)=>{
	return {
		type: ACTIVE_NOTIFICATION,
		payload: status
	}
}
export const userSignUpSuccess = (authUser) => {
	return {
		type: SIGNUP_USER_SUCCESS,
		payload: authUser
	};
};

export const userSignInSuccess = (token,user) => {
	return {
		type: SIGNIN_USER_SUCCESS,
		payload: {accessToken:token,authUser:user}
	}
};
export const userSignOutSuccess = () => {
	return {
		type: SIGNOUT_USER_SUCCESS,
	}
};

export const showAuthMessage = (message) => {
	return {
		type: SHOW_MESSAGE,
		payload: message
	};
};


export const userGoogleSignIn = () => {
	return {
		type: SIGNIN_GOOGLE_USER
	};
};
export const userGoogleSignInSuccess = (authUser) => {
	return {
		type: SIGNIN_GOOGLE_USER_SUCCESS,
		payload: authUser
	};
};
export const userFacebookSignIn = () => {
	return {
		type: SIGNIN_FACEBOOK_USER
	};
};
export const userFacebookSignInSuccess = (authUser) => {
	return {
		type: SIGNIN_FACEBOOK_USER_SUCCESS,
		payload: authUser
	};
};
export const setInitUrl = (url) => {
	return {
		type: INIT_URL,
		payload: url
	};
};
export const userTwitterSignIn = () => {
	return {
		type: SIGNIN_TWITTER_USER
	};
};
export const userTwitterSignInSuccess = (authUser) => {
	return {
		type: SIGNIN_TWITTER_USER_SUCCESS,
		payload: authUser
	};
};
export const userGithubSignIn = () => {
	return {
		type: SIGNIN_GITHUB_USER
	};
};
export const userGithubSignInSuccess = (authUser) => {
	return {
		type: SIGNIN_GITHUB_USER_SUCCESS,
		payload: authUser
	};
};
export const showAuthLoader = () => {
	return {
		type: ON_SHOW_LOADER,
	};
};

export const hideMessage = () => {
	return {
		type: HIDE_MESSAGE,
	};
};
export const hideAuthLoader = () => {
	return {
		type: ON_HIDE_LOADER,
	};
};
export const getProfileInfo = (callback) => {
	return {
		type: GET_PROFILE_INFO,
		callback,
	};
};

export const sendSignUpData = (data,callback) => {
	return {
		type: SEND_SIGNUP_DATA,
		payload:data,
		callback
	};
};

export const sendSignUpDirectData = (data) => {
	return {
		type: SEND_SIGNUP_DIRECT_DATA,
		payload:data
	};
};

export const profileInfoLoaded = (data) => {
	return {
		type: PROFILE_INFO_LOADED,
		payload:data
	};
};
export const userSignUpError = (data) => {
	return {
		type: USER_SIGNUP_ERROR,
		payload:data
	};
};
export const appInternalError = (data) => {
	return {
		type: APP_INTERNAL_ERROR,
		payload:data
	};
};
export const sendRecovery = (payload) => {
	return {
		type: APP_SEND_RECOVERY,
		payload:payload
	};
};
export const sendPasswordRecoverySuccess = (payload) => {
	return {
		type: APP_RECOVERY_SUCCESS,
		payload: payload.message
	};
};
export const hideRecoveryMessage = () => {
	return {
		type: HIDE_RECOVERY_MESSAGE,
	};
};
export const changePassword = (payload,callback) => {
	return {
		type: APP_CHANGE_PASSWORD,
		payload:payload,
		callback
	};
};
export const changePasswordSuccess = () => {
	return {
		type: APP_CHANGE_PASSWORD_SUCCESS
	};
};
export const saveProfilePhoto = (payload,callback) =>{
	return {
		type: APP_UPLOAD_PHOTO,
		payload:payload,
		callback
	}
}
export const saveProfilePhotoSuccess = () =>{
	return {
		type: APP_UPLOAD_PHOTO_SUCCESS
	}
}
