import {
	// DEST_LOADING,
	DEST_DESTINATION_REQUESTED,
	DEST_TARIFF_REQUESTED,
	// DEST_VIEW_MODE,
	DEST_DATA_LOADED,
} from 'constants/ActionTypes';

export const loadDestinations = (payload) => {
	return {
		type: DEST_DESTINATION_REQUESTED,
		payload: payload,
		origen: 'destinations',
	};
};
export const loadTariff = (payload) => {
	return {
		type: DEST_TARIFF_REQUESTED,
		payload: payload,
		origen: 'tariff',
	};
};
export const dataLoaded = (origen, payload) => {
	return {
		type: DEST_DATA_LOADED,
		origen: origen,
		payload: payload,
	};
};
// export const changeViewMode = (mode = 'grid') => {
// 	return {
// 		type: DEST_VIEW_MODE,
// 		mode: mode
// 	};
// };
// export const showLoadingMask = (loading = true) => {
// 	return {
// 		type: DEST_LOADING,
// 		loading: loading
// 	};
// };
