import React from 'react';
import {ConnectedRouter} from 'connected-react-router'
import {Provider} from 'react-redux';
import {Route, Switch} from 'react-router-dom';

import configureStore, {history} from './store';
// import './firebase/firebase';
import App from './containers/App';
import { setAuthHeader,setLangHeader,setCurrencyHeader } from 'util/Api';

export const store = configureStore();

store.subscribe((a,b) => {
let token = store.getState().auth.userToken;
  setAuthHeader(token);
  setLangHeader(store.getState().settings.locale.locale);
  if(store.getState().settings.currency){
    setCurrencyHeader(store.getState().settings.currency.simbolo);
  }
})

const MainApp = () =>
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/" component={App}/>
      </Switch>
    </ConnectedRouter>
  </Provider>;


export default MainApp;