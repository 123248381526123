import {all, call, fork, takeEvery} from "redux-saga/effects";

// import {miceDataSaved} from "actions/Mice";
// import {handleRequestException} from "actions/App";
import Api from "util/Api";
import { FEATURED_LOAD } from "../constants/ActionTypes";


const featuredLoadFnRequest = async (payload) =>
    await  Api.get("sugerencias",payload)
        .then(authUser => authUser)
        .catch(error => error);



function* featuredLoadFn({payload,callback}) {
    try {
        const response = yield call(featuredLoadFnRequest,payload);
        // if (response.response.status === 200) {
            callback(response);
            // yield put(miceDataSaved(response));
        // } else {
            // yield put(miceDataSaved(response));
            // yield put(handleRequestException(response));        
        // }
    } catch (error) {
        // yield put(blogDataLoaded(error));
    }
}


export function* featuredLoad() {
    yield takeEvery(FEATURED_LOAD, featuredLoadFn);
}

export default function* rootSaga() {
    yield all([
        fork(featuredLoad)
    ]);
}