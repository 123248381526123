import React from 'react';
// import {Alert} from 'reactstrap';
// import Alert from 'components/Alert';
import {Link} from 'react-router-dom'
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import {changePassword} from 'actions/Auth';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class ChangePassword extends React.Component {
	constructor() {
		super();
		this.state = {
			email: '',
			token: '',
			password: '',
			password_confirmation: '',
			showMessage:false,
			alertTitle:'',
			alertType:'',
			alertMessage:'',
			color: ''
		}
	}

	componentDidMount() {
        // custom rule will have name 'isPasswordMatch'
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (this.state.password_confirmation !== this.state.password && this.state.password_confirmation && this.state.password) {
                return false;
            }
            return true;
		});
		if(this.props.location.search){
			let search = this.props.location.search.substring(1);
			let object = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
			// console.log(object)
			this.setState({token:object.token, email: object.email})
		}
	}

	closeAlert=()=>{
		this.setState({
			showMessage:false
		},()=>{
			if(this.state.alertType === 'success'){
				if(this.props.authUser && (this.props.authUser && this.props.authUser.invitado)) {
					this.props.history.push({ pathname: "/home" });
				} else {
					this.props.history.push({ pathname: "/signin" });
				}
			}
		});
	}

	render() {
		const {
			alertTitle,
			showMessage,
			alertType,
			alertMessage,
			email,
			token,
			password,
			password_confirmation
		} = this.state;
		const {loader} = this.props;
		return (
			<div
				className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3"
			>
				<div className="app-login-main-content">
					<div className="app-logo-content d-flex align-items-center justify-content-center">
						<Link className="logo-lg" to="/" title="Jambo">
						<img alt="" src={process.env.REACT_APP_LOGO || require("assets/images/logo.png")} />
						</Link>
					</div>
					<div className="app-login-content">
						<div className="app-login-header mb-4">
							<h1><IntlMessages id="app.changepassword"/></h1>
						</div>
						<div className="app-login-form">
							<ValidatorForm
								ref="form"
								onSubmit={() => {
									this.props.changePassword({
										email:email,
									    token:token,
									    password:password,
									    password_confirmation:password_confirmation
									},(res)=>{
										if(res.success){
											this.setState({
												showMessage:true,
												alertTitle:"notification.successMessage",
												alertMessage:[<IntlMessages id="app.passchangesuccessfull"/>],
												alertType:'success'
											});
										}else{
											this.setState({
												showMessage:true,
												alertTitle:"notification.errorMessage",
												alertMessage:[<IntlMessages id={'app.passchangefailure_'+res.message}/>],
												alertType:'danger'
											});
										}
									});
								}}
							>
								<TextValidator
									type="password"
									name="password"
									label={<IntlMessages id="app.pass"/>}
									fullWidth
									onChange={(event) => this.setState({password: event.target.value})}
									value={password}
									margin="normal"
									className="mt-1 my-sm-3"
									validators={['required','isPasswordMatch']}
									errorMessages={[<IntlMessages id="app.camporequerido" />,<IntlMessages id="app.passwordMismatch" />]}
								></TextValidator>
								<TextValidator
									type="password"
									name="password_confirmation"
									label={<IntlMessages id="app.verificarPass"/>}
									fullWidth
									onChange={(event) => this.setState({password_confirmation: event.target.value})}
									value={password_confirmation}
									margin="normal"
									className="mt-1 my-sm-3"
									validators={['required','isPasswordMatch']}
									errorMessages={[<IntlMessages id="app.camporequerido" />,<IntlMessages id="app.passwordMismatch" />]}
								></TextValidator>
								<Button
									variant="contained"
									type="submit"
									color="primary"
								>
									<IntlMessages id="app.changepassword" />
								</Button>
							</ValidatorForm>
						</div>
					</div>
				</div>
				{
					loader &&
					<div className="loader-view">
						<CircularProgress/>
					</div>
				}
				{
					showMessage &&
					<Dialog
						open={showMessage}
						onClose={this.handleClose}
						aria-labelledby="form-dialog-title"
					>
						<DialogTitle id="form-dialog-title">
							<div className={alertType ? 'text-' + alertType : ''}>
								<IntlMessages id={alertTitle} />
							</div>
							<DialogContent>
								<DialogContentText>
									{alertMessage}
								</DialogContentText>
							</DialogContent>
						</DialogTitle>
						<DialogActions>
							<Button onClick={this.closeAlert} color="primary">
								Ok
						</Button>
						</DialogActions>
					</Dialog>
					// <Alert {...this.props} hideMessage={this.closeAlert} type={alertType} content={alertMessage} title={alertTitle} />
				}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	authUser: state.auth.authUser,
	loader: state.auth.loader
});

export default connect(mapStateToProps, {
	changePassword,
})(ChangePassword);
