import React from 'react';
import {Redirect, Route} from 'react-router-dom';

export default   ({component: Component, logged,pages,authUser, ...rest}) =>
  <Route
    {...rest}
    render={props =>
      logged && (authUser && authUser.invitado)
        ? <Redirect
		to={{
		  pathname: '/home',
		  state: {from: props.location}
		}}
	  />
        : <Component {...props} logged={logged} />}
  />;