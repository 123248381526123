import {
	GET_PAYMENT_STATUS,
	PAYMENT_STATUS_LOADED,
} from 'constants/ActionTypes';

const INIT_STATE = {
	data: {
		data: {
			estado: '',
			valor: 0,
			usuario: '',
			detalle: '',
			cus: '',
			id_reserva: '',
			fecha: '',
			logo: ''
		}
	},
	loading: false,
	showModal: false
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case GET_PAYMENT_STATUS: {
			return {
				...state,
				loading: true,
			}
		}
		case PAYMENT_STATUS_LOADED: {
			return {
				...state,
				loading: false,
				data: action.payload
			}
		}
		default:
			return state;
	}
}