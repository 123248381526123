import React from 'react';
import Button from '@material-ui/core/Button';
// import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IntlMessages from 'util/IntlMessages';
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import {Link} from 'react-router-dom';
export default class Alert extends React.Component {
  state = {
    open: true,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.props.hideMessage();
    // this.setState({ open: false });
  };

  resetPassword = () => {
	this.props.hideMessage();
	this.props.history.push({ pathname: "/forgotpassword" });
  }

  render() {
	const {type,content,title} = this.props;
    let renderContent = '';

    if(typeof content === 'object'){
		content.forEach((item,key)=>{
			renderContent = renderContent + item
		})
    }else{
      renderContent = content;
	}

    return (

      <div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title"><div className={type?'text-'+type:''}><IntlMessages id={title}/></div></DialogTitle>
          <DialogContent>
            <DialogContentText>
              <span dangerouslySetInnerHTML={{ __html: renderContent }}></span>
			  {
				this.props.addButton
					&&
					<Button
						color="primary"
						onClick={this.resetPassword}
					>
						<IntlMessages id="appModule.resetPassword"/>
					</Button>
			  }
            </DialogContentText>

          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
