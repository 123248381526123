import React from 'react';
import IntlMessages from 'util/IntlMessages';
import BackgroundComponent from 'containers/BackgroundComponent';
import './styles/styleComingSoon.css';

const ComingSoon = () => (
	// <BackgroundComponent>
	// 	<div className="main-div"
	// 		// style={{height:'100vh'}}
	// 	>
	// 		<img
	// 			className="img-logo"
	// 			src={'https://s3.amazonaws.com/opal.travel/opal.travel_english_white.png'}
	// 			alt="post"
	// 		/>
	// 		<br/>
	// 		<h2>COMING SOON</h2>
	// 	</div>
	// </BackgroundComponent>

	<BackgroundComponent BackgroundColor={'#287888c4'}>
		<div className="coming-soon">
			<div style={{height:'100vh'}}
				className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
				<div className="app-login-main-content">

					<div className="app-logo-content d-flex align-items-center justify-content-center">
						<img
							className="img-logo"
							src={'https://s3.amazonaws.com/opal.travel/opal.travel_english_white.png'}
							alt="post"
						/>
					</div>

					<div className="app-login-content">
						<div className="app-login-header mb-4">
							<h1 className="title-coming-soon">
								<IntlMessages id="app.comingSoon"/>
							</h1>
						</div>

						<div className="app-login-form">
							<h3 className="title-coming-soon-2">
								<IntlMessages id="app.comingSoonText"/>
							</h3>
						</div>
					</div>

				</div>

			</div>
		</div>
	</BackgroundComponent>
);

export default ComingSoon;
