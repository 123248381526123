import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import Api from "util/Api";
import { hotelConfirmLoaded } from "../actions/HotelConfirm";
import { handleRequestException } from "../actions/App";
import { GET_HOTEL_CONFIRMED } from "../constants/ActionTypes";

const hotelConfirmFnRequest = async (payload) =>
	await Api.put('reserva/acomodacion/confirmar', payload)
		.then(authUser => authUser)
		.catch(error => error);

export function* hotelConfirm() {
	yield takeEvery(GET_HOTEL_CONFIRMED, function* ({ payload }) {
		try {
			const response = yield call(hotelConfirmFnRequest, payload);
			if (response.response.status === 200) {
				yield put(hotelConfirmLoaded(response));
			} else {
				yield [
					put(handleRequestException(response))
				];
			}
		} catch (error) {
			console.error(error)
		}
	});
}

export default function* rootSaga() {
	yield all([
		fork(hotelConfirm)
	])
}