import './app.css';
import React from 'react';
import { connect } from 'react-redux';
import IntlMessages from 'util/IntlMessages';
import {
	Dialog,
	// DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	// TextField,
	Button,
	Slide,
	Typography,
} from '@material-ui/core';
import { AlertShowHide } from 'actions/App';
import { activeNotification } from 'actions/Auth';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

let theme = createTheme();
const colors = theme.palette
theme = responsiveFontSizes(theme);

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide
		direction="down"
		ref={ref}
		{...props}
	/>;
});

class ActiveNotification extends React.Component {

	state = {
		email: '',
		disabled: false,
	}

	closeNotification = () => {
		this.props.AlertShowHide(false);
	}

	handleChange = (event) => {
		this.setState({ email: event.target.value })
	}

	validatorListener = (result) => {
		this.setState({ disabled: !result })
	}

	submit = (status) => {
		if (this.props.authUser) {
			if (this.props.authUser && (this.props.authUser.invitado && !this.state.email)) {
				if (status === 1) {
					this.refs.emailField.makeInvalid();
				} else {
					this.props.AlertShowHide(false);
				}
			} else {
				this.props.activeNotification({
					email: this.state.email,
					status: status
				});
				this.props.AlertShowHide(false);
			}
		}
		localStorage.setItem('suscrito', status)
	}


	mailIcon = (props) => {
		return (
			<svg {...props} viewBox="0 0 512 512">
				<path d="M492 178L334 20a34 34 0 00-48 0L40 266a34 34 0 000 48l158 158c13 13 34 13 48 0l246-246c13-14 13-35 0-48z" fill={props.colorA} />
				<path fill={props.colorB} d="M224 480V274l70-70h206z" />
				<path d="M40 266L286 20l8-6v226c0 19-15 34-34 34H34l6-8z" fill={props.colorC} />
				<path d="M279 13L33 259a44 44 0 000 62l39 40-43 43c-2 2-3 5-3 7s1 6 3 8c4 3 10 3 14 0l44-44 18 18L3 495c-2 2-3 4-3 7s1 5 3 7c4 4 10 4 14 0l102-102 72 72c17 17 45 17 62 0l246-246a44 44 0 000-62L341 13a44 44 0 00-62 0zm206 172l6 9H304V21c8-2 17 0 23 6l158 158zM239 465l-5 3V284h26c24 0 44-20 44-44v-26h115l-58 58a10 10 0 000 14c4 4 10 4 14 0l72-72h41l-3 5-246 246zM41 284h173v187l-9-6L47 307c-6-6-8-15-6-23zM284 36v204c0 13-11 24-24 24h-82l30-30a10 10 0 10-14-14l-44 44h-29l90-90a10 10 0 00-14-14L93 264H56L284 36z" />
				<path d="M236 135a10 10 0 010 14 10 10 0 01-14 0 10 10 0 010-14 10 10 0 0114 0zM20 437a10 10 0 01-17 7c-2-1-3-4-3-7s1-5 3-7 4-3 7-3 5 1 7 3 3 5 3 7zM65 502l2-7 46-46a10 10 0 0114 15l-45 45a10 10 0 01-17-7z" />
			</svg>
		)
	}

	render() {
		const { authUser } = this.props
		return (
			<Dialog
				open={this.props.statusAlert}
				TransitionComponent={Transition}
				onClose={this.closeNotification}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>

				<DialogContent>
					<div style={{ width: '7em', margin: 'auto' }}>
						{this.mailIcon({
							colorA: colors.primary.main,
							colorB: colors.primary.light,
							colorC: colors.primary.dark,
						})}
					</div>
					<ThemeProvider theme={theme}>
						<Typography variant={'h3'} align={'center'} color={"primary"} style={{ textTransform: 'uppercase', margin: '10px 0 20px' }}>¡<IntlMessages id="app.suscribe" />!</Typography>
					</ThemeProvider>

					<DialogContentText align={"center"}>
						<IntlMessages id="app.suscribeMessage" />
					</DialogContentText>
					{
						authUser ? authUser.invitado ?
							<ValidatorForm>
								<TextValidator
									label={<IntlMessages id="appModule.email" />}
									fullWidth
									ref={"emailField"}
									onChange={this.handleChange}
									value={this.state.email}
									margin="normal"
									variant={"outlined"}
									name="email"
									className="mt-1 my-sm-3"
									validators={['required', 'isEmail']}
									errorMessages={[<IntlMessages id="app.camporequerido" />, <IntlMessages id="app.emaillinvalido" />]}
									validatorListener={this.validatorListener}
								></TextValidator>
							</ValidatorForm>
								:
								null
							:
							null
					}
				</DialogContent>

				<DialogActions className="justify-content-center">
					<Button onClick={() => this.submit(2)} color="secondary">
						<IntlMessages id="app.decline" />
					</Button>
					<Button onClick={() => this.submit(1)} variant="contained" color="primary" disabled={this.state.disabled}>
						<IntlMessages id="app.suscribeAccept" />
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

}

const mapStateToProps = state => ({
	statusAlert: state.app.statusAlert,
	authUser: state.auth.authUser
});

export default connect(mapStateToProps, {
	AlertShowHide,
	activeNotification
})(ActiveNotification);