import {
	OPERATIONS_SERVICIOS_REQUEST,
	OPERATIONS_SERVICIOS_LOADED,
	OPERATIONS_SERVICIO_DETALLE_REQUEST,
	OPERATIONS_SERVICIO_DETALLE_LOADED,
	OPERATIONS_EXTRACTOS_REQUEST,
	OPERATIONS_EXTRACTOS_LOADED,
	OPERATIONS_CHANGE_SERVICIO_STATE,
} from 'constants/ActionTypes';

export const serviciosRequest = (data,callback) => {
	return {
			type: OPERATIONS_SERVICIOS_REQUEST,
			payload: data,
			callback
	};
};
export const serviciosLoaded = (data) => {
	return {
			type: OPERATIONS_SERVICIOS_LOADED,
			payload: data,
	};
};
export const servicioDetalleRequest = (data) => {
	return {
			type: OPERATIONS_SERVICIO_DETALLE_REQUEST,
			payload: data,
	};
};
export const servicioDetalleLoaded = (data) => {
	return {
			type: OPERATIONS_SERVICIO_DETALLE_LOADED,
			payload: data,
	};
};
export const extractosRequest = (data) => {
	return {
			type: OPERATIONS_EXTRACTOS_REQUEST,
			payload: data,
	};
};
export const extractosLoaded = (data) => {
	return {
			type: OPERATIONS_EXTRACTOS_LOADED,
			payload: data,
	};
};
export const changeServicioState = (data, callback) => {
	return {
			type: OPERATIONS_CHANGE_SERVICIO_STATE,
			payload: data,
			callback
	};
};