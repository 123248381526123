import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import Api from "util/Api";

import {
	DEST_DESTINATION_REQUESTED,
	DEST_TARIFF_REQUESTED,
} from 'constants/ActionTypes';

import {
	showModalError,
} from "actions/App";

import { dataLoaded } from "../actions/Destination";

// TARIFAS
const apiLoadTariff = async (payload) =>
	await  Api.get("frontend/archivo/destinos",payload)
		.then(authUser => authUser)
		.catch(error => error);


export function* doLoadTariff() {
	yield takeEvery(DEST_TARIFF_REQUESTED, function*({payload}) {
		try {
			const response = yield call(apiLoadTariff,payload);
			if (response.response.status === 200) {
				yield put(dataLoaded("tariff", response.items));
			} else {
				yield put(showModalError(response.response.status,'modal','/packages'));
			}
		} catch (error) {
		}
	});
}

// DESTINOS
const apiLoadDestinations = async (payload) =>
	await  Api.get("frontend/destinos",payload)
		.then(authUser => authUser)
		.catch(error => error);


export function* doLoadDestinations() {
	yield takeEvery(DEST_DESTINATION_REQUESTED, function*({payload}) {
		try {
			const response = yield call(apiLoadDestinations,payload);
			if (response.response.status === 200) {
				yield put(dataLoaded("destinations", response.items));
			} else {
				yield put(showModalError(response.response.status,'modal','/home'));
			}
		} catch (error) {
		}
	});
}

export default function* rootSaga() {
	yield all([
		fork(doLoadDestinations),
		fork(doLoadTariff),
	]);
}