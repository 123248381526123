import {
	CHANGE_DIRECTION,
	CHANGE_NAVIGATION_STYLE,
	DARK_THEME,
	DRAWER_TYPE,
	HORIZONTAL_MENU_POSITION,
	SWITCH_LANGUAGE,
	SWITCH_CURRENCY,
	THEME_COLOR,
	TOGGLE_COLLAPSED_NAV,
	WINDOW_WIDTH,
	LOAD_CURRENCIES,
	SET_GLOBAL_CONFIG,
	GET_GLOBAL_CONFIG,
	GET_LOCAL_CURRENCIE,
} from 'constants/ActionTypes';

export function toggleCollapsedNav(isNavCollapsed) {
	return {type: TOGGLE_COLLAPSED_NAV, isNavCollapsed};
}

export function setDrawerType(drawerType) {
	return {type: DRAWER_TYPE, drawerType};
}

export function updateWindowWidth(width) {
	return {type: WINDOW_WIDTH, width};
}

export function setThemeColor(color) {
	return {type: THEME_COLOR, color};
}

export function setDarkTheme() {
	return {type: DARK_THEME};
}

export function changeDirection() {
	return {type: CHANGE_DIRECTION};
}

export function changeNavigationStyle(layoutType) {
	return {
		type: CHANGE_NAVIGATION_STYLE,
		payload: layoutType
	};
}

export function setHorizontalMenuPosition(navigationPosition) {
	return {
		type: HORIZONTAL_MENU_POSITION,
		payload: navigationPosition
	};
}

export function switchLanguage(locale,noSave) {
	window.recaptchaOptions = {
		lang: locale.locale,
		// useRecaptchaNet: true,
		// removeOnUnmount: false,
	  };
	return {
		type: SWITCH_LANGUAGE,
		payload: locale,
		noSave
	};
}

export function switchCurrency(locale,noSave) {
	return {
		type: SWITCH_CURRENCY,
		payload: locale,
		noSave
	};
}

export function loadCurrencies(locales) {
	return {
		type: LOAD_CURRENCIES,
		payload: locales
	};
}

export function getGlobalConfig(callback) {
	return {
		type: GET_GLOBAL_CONFIG,
		callback: callback,
	};
}

export function setGlobalConfig(payload) {
	return {
		type: SET_GLOBAL_CONFIG,
		payload: payload
	};
}

export function getLocalCurrencie(payload){
	return {
		type: GET_LOCAL_CURRENCIE,
		payload: payload
	}
}
