import {
	GET_HOTEL_CONFIRMED,
	HOTEL_CONFIRMED_LOADED,
} from 'constants/ActionTypes';

const INIT_STATE = {
	data: {},
	loading: false,
	showModal: false
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case GET_HOTEL_CONFIRMED: {
			return {
				...state,
				loading: true,
			}
		}
		case HOTEL_CONFIRMED_LOADED: {
			return {
				...state,
				loading: false,
				data: action.payload
			}
		}
		default:
			return state;
	}
}