import {
	GET_EMPRESA_REQUEST,
	GET_EMPRESA_DATA
} from 'constants/ActionTypes';


export const empresaRequest = () => {
	return {
		type: GET_EMPRESA_REQUEST,
	};
};

export const empresaData = (payload) => {
	return {
		type: GET_EMPRESA_DATA,
		payload: payload,
	};
};