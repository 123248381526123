import {
    POST_FEATURED_REQUESTED,
    POST_PAGES_REQUESTED,
    POST_PAGE_REQUESTED,
    POST_DATA_LOADED,
    POST_LOADING
} from 'constants/ActionTypes';

export const loadFeaturedPages = (payload) => {
    return {
        type: POST_FEATURED_REQUESTED,
        payload: payload
    };
};
// pages
export const pagesRequest = (payload) => {
    return {
        type: POST_PAGES_REQUESTED,
        payload: payload
    };
};
// one page
export const pageRequest = (payload) => {
    return {
        type: POST_PAGE_REQUESTED,
        payload: payload
    };
};
export const dataLoaded = (origen, payload) => {
    return {
        type: POST_DATA_LOADED,
        origen: origen,
        payload: payload
    };
};
export const showLoadingMask = (loading = true) => {
    return {
        type: POST_LOADING,
        loading: loading
    };
};