import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import Api from "util/Api";
import { ipqrsSaved } from "../actions/Ipqrs";
import { handleRequestException } from "../actions/App";
import { SUBMIT_IPQRS_FORM } from "../constants/ActionTypes";

const ipqrsSubmitFnRequest = async (payload) =>
	await Api.upload('ipqrs', payload)
		.then(authUser => authUser)
		.catch(error => error);

export function* ipqrsSubmit() {
	yield takeEvery(SUBMIT_IPQRS_FORM, function* ({ payload, cb, origen }) {
		try {
			const response = yield call(ipqrsSubmitFnRequest, payload);
			if (response.response.status === 200) {
				cb();
				yield put(ipqrsSaved(response));
			} else {
				yield [
					put(ipqrsSaved()),
					put(handleRequestException(response, origen))
				];
			}
		} catch (error) {
			console.error(error)
		}
	});
}

export default function* rootSaga() {
	yield all([
		fork(ipqrsSubmit)
	])
}