import {all, call, put, fork, takeEvery} from "redux-saga/effects";

import Api from "util/Api";
import { GET_EMPRESA_REQUEST } from "../constants/ActionTypes";
import { empresaData } from "../actions/Empresa";

const apiEmpresaRequest = async () =>
	await  Api.get("empresa")
	.catch(error => error);

export function* getCotizacionRequest() {
	yield takeEvery(GET_EMPRESA_REQUEST, function*() {
		try {
			const response = yield call(apiEmpresaRequest);
			const empresa = response.response.status === 200 ? response.data : null;

			yield put(empresaData(empresa));
		} catch (error) {
		}
	});
}

export default function* rootSaga() {
	yield all([
		fork(getCotizacionRequest),
	]);
}