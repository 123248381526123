import {
    MICE_SAVE,
    MICE_DATA_SAVED
} from 'constants/ActionTypes';

export const miceSave = (data,callback) => {
    return {
        type: MICE_SAVE,
        payload: data,
        callback
    };
};

export const miceDataSaved = (data) => {
    return {
        type: MICE_DATA_SAVED,
        payload: data
    };
};



