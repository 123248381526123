import React from 'react';
import {Link} from 'react-router-dom'
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import {sendRecovery,hideMessage} from 'actions/Auth';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import BackgroundComponent from 'containers/BackgroundComponent';
import { hideRecoveryMessage } from 'actions/Auth';
import { DialogContentText } from '@material-ui/core';
class ForgotPassword extends React.Component {
	constructor() {
		super();
		this.state = {
			email: ''
		}
	}

	render() {
		const {
			email
		} = this.state;
		const {alertMessage, showPasswordMessage, loader} = this.props;
		return (
			<BackgroundComponent>
				<div style={{height:'100vh'}}
					className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
					<div className="app-login-main-content">

						<div className="app-logo-content d-flex align-items-center justify-content-center">
							<Link className="logo-lg" to="/" title="Jambo">
								<img alt="" src={process.env.REACT_APP_LOGO || require("assets/images/logo.png")} />
							</Link>
						</div>

						<div className="app-login-content">
							<div className="app-login-header mb-4">
								<h1><IntlMessages id="app.passwordRecovery"/></h1>
							</div>

							<div className="app-login-form">
								<ValidatorForm
									ref="form"
									onSubmit={() => {
										this.props.sendRecovery({email:email});
									}}
								>
									{
										showPasswordMessage?
											<DialogContentText dangerouslySetInnerHTML={{ __html: alertMessage }}></DialogContentText>
										:
										<TextValidator
											label={<IntlMessages id="appModule.email"/>}
											fullWidth
											onChange={(event) => this.setState({email: event.target.value})}
											value={email}
											margin="normal"
											variant={"outlined"}
											name="email"
											className="mt-1 my-sm-3"
											validators={['required', 'isEmail']}
											errorMessages={[<IntlMessages id="app.camporequerido" />,<IntlMessages id="app.emaillinvalido" />]}
										></TextValidator>
									}
									<div className="mb-3 d-flex align-items-center justify-content-between">
										<Button
											style={{ fontSize: 12 }}
											variant="contained"
											type="submit"
											color="primary"
											hidden={showPasswordMessage}
										>
											<IntlMessages id="app.sendRecovery"/>
										</Button>
										<div>
											<Link to="/signin" onClick={() => {
												this.props.hideRecoveryMessage();
											}}>
												<IntlMessages id="app.back"/>
											</Link>
											&nbsp;|&nbsp;
											<Link to="/home">
												<IntlMessages id="pages.home"/>
											</Link>
										</div>
									</div>
								</ValidatorForm>
							</div>
						</div>

					</div>
					{
						loader &&
						<div className="loader-view">
							<CircularProgress/>
						</div>
					}

				</div>
			</BackgroundComponent>
		);
	}
}

const mapStateToProps = state => ({
    loader: state.auth.loader,
	showPasswordMessage: state.auth.showPasswordMessage,
	alertMessage: state.auth.alertMessage
});

export default connect(mapStateToProps, {
	sendRecovery,hideMessage,hideRecoveryMessage
})(ForgotPassword);
