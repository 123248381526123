import {
    VALIDATION_ERROR,
    INTERNAL_SERVER_ERROR,
	UNKNOWN_ERROR,
	MODAL_ERROR_HIDE,
	MODAL_ERROR_SHOW,
    MAIN_ALERT_HIDE,
	MAIN_ALERT_SHOW,
	OPEN_MODAL_LOGIN,
	CLOSE_MODAL_LOGIN,
	DISABLED_BUTTON_SEARCH,
	ALERT_SHOW_HIDE,
	ERROR_MESSAGE,
} from 'constants/ActionTypes';

export const handleRequestException = (response, origen) => {
    switch(response.response.status){
        case 422:
            return {
                type: VALIDATION_ERROR,
				payload: response,
				origen
            };
        case 500:
            return {
                type: INTERNAL_SERVER_ERROR,
				payload: response,
				origen
            };
        default:
            return {
                type: UNKNOWN_ERROR,
				payload: response,
				origen
            };
    }
};

export const hideModalError = () => {
    return {
		type: MODAL_ERROR_HIDE,
    };
};

export const showModalError = (content,action,url) => {
	return {
		type: MODAL_ERROR_SHOW,
		content,
		action,
		url
	}
}

export const errorMessage = (status,message) => {
	return {
		type: ERROR_MESSAGE,
		status,
		message
	}
}

export const AlertShowHide = (status) =>{
	return {
		type: ALERT_SHOW_HIDE,
		status
	}
}

export const disabledButtonSearch = (status) => {
	return {
		type: DISABLED_BUTTON_SEARCH,
		status
	}
}

export const hideMainAlert = () => {
    return {
		type: MAIN_ALERT_HIDE,
    };
};

export const openModalLogin = () =>{
	return {
		type: OPEN_MODAL_LOGIN
	}
}

export const closeModalLogin = () =>{
	return {
		type: CLOSE_MODAL_LOGIN
	}
}

export const showMainAlert = (title,content) => {
    return {
        type: MAIN_ALERT_SHOW,
        title,
        content
    };
};
