import {
    VALIDATION_ERROR,
	INTERNAL_SERVER_ERROR,
	MODAL_ERROR_HIDE,
	MODAL_ERROR_SHOW,
    MAIN_ALERT_HIDE,
	MAIN_ALERT_SHOW,
	OPEN_MODAL_LOGIN,
	CLOSE_MODAL_LOGIN,
	DISABLED_BUTTON_SEARCH,
	ALERT_SHOW_HIDE,
	ERROR_MESSAGE,
} from "constants/ActionTypes";
const modalContent={
	app: '',
	workWithUs: '',
	ipqrs: '',
	contact: ''
}
const errorContent={
	modalUp: false,
	code: null,
	url: ''
}
const INIT_STATE = {
	showError: false,
	codeError: null,
	urlError: null,
	actionError: null,
    showModal: false,
	modalContent,
	errorContent,
	lastResponseStatus:null,
	showModalLogin:false,
	buttonSearch: false,
	statusAlert: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case VALIDATION_ERROR: {
			let mContent = {...modalContent};
            mContent.app =[];
            mContent.ipqrs =[];
            mContent.workWithUs =[];
            mContent.contact =[];
            for(let item in action.payload.errors){
                let obj = action.payload.errors[item];
                mContent[action.origen].push(obj[0]);
			}

            return {
                ...state,
                showModal:true,
                modalTitle:"app.validationError",
                modalContent: mContent,
                // payload:1
            }
		}

        case INTERNAL_SERVER_ERROR: {
            return {
                ...state,
            }
		}
		case MODAL_ERROR_HIDE:{
			return {
				...state,
				showError: false,
				codeError: null,
				urlError: null,
				actionError: null,
			}
		}
		case MODAL_ERROR_SHOW:{
			//action
			//- modal
			//- print
			return {
				...state,
				showError: true,
				codeError: action.content,
				urlError: action.url,
				actionError: action.action
			}
		}
		case ERROR_MESSAGE:{
			return {
				...state,
				status: action.status,
				message: action.message
			}
		}
		case ALERT_SHOW_HIDE:{
			return {
				...state,
				statusAlert: action.status
			}
		}
		case DISABLED_BUTTON_SEARCH:{
			return {
				...state,
				buttonSearch: action.status
			}
		}
		case OPEN_MODAL_LOGIN:{
			return {
				...state,
				showModalLogin:true
			}
		}
		case CLOSE_MODAL_LOGIN:{
			return {
				...state,
				showModalLogin:false
			}
		}
        case MAIN_ALERT_HIDE:{
            return {
                ...state,
                showModal:false,
                modalContent: INIT_STATE.modalContent
            }
        }
        case MAIN_ALERT_SHOW:{
            return {
                ...state,
                showModal:true,
                modalContent:action.content,
                modalTitle:action.title
            }
        }
        default:
            return state;
    }
}
