import React from 'react';
import {loadAutoCompleteCarta} from 'actions/AutoComplete';
import {connect} from 'react-redux';
import AsyncPaginate from 'react-select-async-paginate';


class AutoCompleteInfiniteScroll extends React.Component {
	state = {
		selectedOption: null,
		options: []
	}
	handleChange = (selectedOption) => {
		this.setState({ selectedOption });
	}

	loadOptions = async (search, prevOptions, { page }) => {
		const data = await new Promise((resolve, reject) => {
			this.props.loadAutoCompleteCarta(
				{ query: search, page, ...this.props.queryData },
				this.props.url,
				this.props.id,
				(items, total)=>{
					this.setState({
						options: items
					})

					resolve({items, total})
				}
			)
		})

		let hasMore = this.state.options.length < data.total;

		return {
			options: data.items,
			hasMore: hasMore,
			additional: {
				page: page + 1
			}
		};
	}

	render(){
		const error = this.props.error?{border: '1px solid red',borderRadius: 5}:{};

		const selectStyles = {
			container: (base, state) => ({
				...base,
				...error,
				opacity: state.isDisabled ? ".5" : "1",
				backgroundColor: "transparent",
			}),
			menuPortal: base => ({
				...base,
				minWidth:200,
			}),
			input: () => ({
				padding: 14,
			}),
			singleValue: (provided, state) => {
				const opacity = state.isDisabled ? 0.5 : 1;
				const transition = 'opacity 300ms';
				return { ...provided, opacity, transition };
			},
			placeholder: (provided) => ({
					...provided,
					padding: 0,
					fontSize: "1rem",
					fontFamily: "Roboto, Helvetica, Arial, sans-serif",
					fontWeight: 400,
					lineHeight: 1,
					letterSpacing: "0.00938em",
			}),
			...this.props.extendStyles
		};

		return <div style={this.props.divStyle}>
			<AsyncPaginate
				cacheOptions
				loadOptions={this.loadOptions}
				additional={{page: 1}}
				menuPortalTarget={document.body}
				debounceTimeout={300}
				onChange={this.props.onChange}
				styles={selectStyles}
				{...this.props}
			/>
		</div>;
	}
}
const mapStateToProps = ({AutoComplete,auth}) => {
	const {logged} = auth;
	return {logged,AutoComplete}
};

export default connect(mapStateToProps, {
	loadAutoCompleteCarta
})(AutoCompleteInfiniteScroll);