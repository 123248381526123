import React, { useState, useEffect } from 'react'
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

export default function HideOnScroll(props) {
	const trigger = useScrollTrigger({ target: props.target || undefined });
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

	useEffect(() => {
		const updateWindowDimensions = () => {
			setIsMobile(window.innerWidth < 768);
		}

		window.addEventListener("resize", updateWindowDimensions);

		return () => {
			window.removeEventListener("resize", updateWindowDimensions);
		}
	}, [props.target])
	return (
		<div
			style={{display: !(trigger && isMobile) ? 'block': 'none'}}
		>
			{props.children}
		</div>
	);
}
