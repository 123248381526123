import {
	SUBMIT_IPQRS_FORM,
	IPQRS_FORM_SUBMITTED,
} from 'constants/ActionTypes';

export const submitIpqrsForm = (page, callback, origen) => {
	return {
		type: SUBMIT_IPQRS_FORM,
		payload: page,
		cb: callback,
		origen
	};
};

export const ipqrsSaved = (data) => {
	return {
		type: IPQRS_FORM_SUBMITTED,
		payload: data
	};
};