import { GET_EMPRESA_REQUEST, GET_EMPRESA_DATA } from "constants/ActionTypes";

const INIT_STATE = {
	loading: false,
	empresa: null,
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case GET_EMPRESA_REQUEST: {
			return {
				...state,
				loading: true,
			};
		}
		case GET_EMPRESA_DATA: {
			return {
				...state,
				loading: false,
				empresa: action.payload
			};
		}
		default:
			return state;
	}
};
