import {all, call, fork, put, takeEvery} from "redux-saga/effects";

import {miceDataSaved} from "actions/Mice";
// import {handleRequestException} from "actions/App";
import Api from "util/Api";
import { MICE_SAVE } from "../constants/ActionTypes";


const miceSaveFnRequest = async (payload) =>
    await  Api.post("mice",payload)
        .then(authUser => authUser)
        .catch(error => error);



function* miceSaveFn({payload,callback}) {
    try {
        const response = yield call(miceSaveFnRequest,payload);
        // if (response.response.status === 200) {
            callback(response);
            yield put(miceDataSaved(response));
        // } else {
            // yield put(miceDataSaved(response));
            // yield put(handleRequestException(response));        
        // }
    } catch (error) {
        // yield put(blogDataLoaded(error));
    }
}


export function* miceSave() {
    yield takeEvery(MICE_SAVE, miceSaveFn);
}

export default function* rootSaga() {
    yield all([
        fork(miceSave)
    ]);
}