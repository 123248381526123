import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import Api from "util/Api";

import {
    POST_FEATURED_REQUESTED,
    POST_PAGES_REQUESTED,
    POST_PAGE_REQUESTED,
} from 'constants/ActionTypes';
import { dataLoaded } from "../actions/Page";

// Lista destacadas
const apiLoadList = async () =>
    await  Api.get("featured-posts")
        .catch(error => error);


export function* doLoadFeatured() {
    yield takeEvery(POST_FEATURED_REQUESTED, function*() {
        try {
            const response = yield call(apiLoadList);
            if (response.response.status === 200) {
                yield put(dataLoaded("featured", response.data));
            } else {
                // alert(response.response.status)
            }
        } catch (error) {
        }
    });
}

// Paginas
const apiPages = async () =>
    await  Api.get("pages")
        .catch(error => error);


export function* doLoadPages() {
    yield takeEvery(POST_PAGES_REQUESTED, function*() {
        try {
            const response = yield call(apiPages);
            if (response.response.status === 200) {
                yield put(dataLoaded("pages", response.data));
            } else {
                alert(response.response.status)
            }
        } catch (error) {
        }
    });
}

// Una pagina
const apiPage = async (payload) =>
    await  Api.get("page",payload)
        .catch(error => error);


export function* doLoadPage() {
    yield takeEvery(POST_PAGE_REQUESTED, function*({payload}) {
        try {
            const response = yield call(apiPage,payload);
            if (response.response.status === 200) {
                yield put(dataLoaded("page", response.data));
            } else {
                alert(response.response.status)
            }
        } catch (error) {
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(doLoadPage),
        fork(doLoadPages),
        fork(doLoadFeatured),
    ]);
}