import {
	OPERATIONS_SERVICIOS_REQUEST,
	OPERATIONS_SERVICIOS_LOADED,
	OPERATIONS_EXTRACTOS_REQUEST,
	OPERATIONS_SERVICIO_DETALLE_REQUEST,
	OPERATIONS_SERVICIO_DETALLE_LOADED,
	OPERATIONS_EXTRACTOS_LOADED,
	OPERATIONS_CHANGE_SERVICIO_STATE,
} from "constants/ActionTypes";

const INIT_STATE = {
	loading: false,
	loadingDetail: false,
	loadingExtractos: false,
	servicios: [],
	detalle: [],
	extractos: [],
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		// Servicios
		case OPERATIONS_SERVICIOS_REQUEST: {
			return {
				...state,
				loading:true,
				servicios: [],
			}
		}
		case OPERATIONS_SERVICIOS_LOADED: {
			return {
				...state,
				loading:false,
				servicios:action.payload
			}
		}
		// Detalle
		case OPERATIONS_SERVICIO_DETALLE_REQUEST: {
			return {
				...state,
				loadingDetail:true,
				detalle: [],
			}
		}
		case OPERATIONS_SERVICIO_DETALLE_LOADED: {
			return {
				...state,
				loadingDetail:false,
				detalle:action.payload
			}
		}
		// Extractos
		case OPERATIONS_EXTRACTOS_REQUEST: {
			return {
				...state,
				loadingExtractos:true,
				extractos: [],
			}
		}
		case OPERATIONS_EXTRACTOS_LOADED: {
			return {
				...state,
				loadingExtractos:false,
				extractos:action.payload
			}
		}
		// Change state
		case OPERATIONS_CHANGE_SERVICIO_STATE: {
			return {
				...state,
			}
		}
		default:
			return state;
	}
}
