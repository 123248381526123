import {
	SUBMIT_IPQRS_FORM,
	IPQRS_FORM_SUBMITTED,
} from 'constants/ActionTypes';

const INIT_STATE = {
	data: [],
	loading: false,
	showModal: false,
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case SUBMIT_IPQRS_FORM: {
			return {
				...state,
				loading: true,
			}
		}
		case IPQRS_FORM_SUBMITTED: {
			return {
				...state,
				loading: false,
				data: action.payload
			}
		}
		default:
			return state;
	}
}