import {
	CHANGE_DIRECTION,
	CHANGE_NAVIGATION_STYLE,
	FIXED_DRAWER,
	HORIZONTAL_MENU_POSITION,
	INSIDE_THE_HEADER,
	SWITCH_LANGUAGE,
	TOGGLE_COLLAPSED_NAV,
	HORIZONTAL_NAVIGATION,
	WINDOW_WIDTH,
	SET_GLOBAL_CONFIG,
	GET_LOCAL_CURRENCIE
} from 'constants/ActionTypes';
import { SWITCH_CURRENCY,LOAD_CURRENCIES } from '../constants/ActionTypes';

const rltLocale = ['ar'];
const initialSettings = {
	navCollapsed: false,
	drawerType: FIXED_DRAWER,
	width: window.innerWidth,
	isDirectionRTL: false,
	navigationStyle: HORIZONTAL_NAVIGATION,
	horizontalNavPosition: INSIDE_THE_HEADER,
	pages:{
		public:["about-us","contact"]
	},
	availableLoginMethods:[
		"password"
	],
	currencies:[],
	currency:null,
	locale: {
		languageId: 'spanish',
		locale: 'es',
		name: 'Español',
		icon: 'es'
	},
	utilidades: [],
	localCurrencie: 'COP'
};

const settings = (state = initialSettings, action) => {
	switch (action.type) {
		case '@@router/LOCATION_CHANGE':
		return {
			...state,
			navCollapsed: false
		};
		case TOGGLE_COLLAPSED_NAV:
		return {
			...state,
			navCollapsed: action.isNavCollapsed
		};
		case WINDOW_WIDTH:
		return {
			...state,
			width: action.width
		};
		case SWITCH_LANGUAGE:
		return {
			...state,
			locale: action.payload,
			isDirectionRTL: rltLocale.includes(action.payload.locale)
		};
		case LOAD_CURRENCIES:
		return {
			...state,
			loader:true,
			currencies: action.payload,
		};
		case SWITCH_CURRENCY:
		return {
			...state,
			currency: action.payload,
		};
		case CHANGE_DIRECTION:
		return {
			...state,
			isDirectionRTL: !state.isDirectionRTL
		};
		case CHANGE_NAVIGATION_STYLE:
		return {
			...state,
			navigationStyle: action.payload
		};
		case HORIZONTAL_MENU_POSITION:
		return {
			...state,
			horizontalNavPosition: action.payload
		};
		case SET_GLOBAL_CONFIG:
		return {
			...state,
			utilidades: action.payload
		};
		case GET_LOCAL_CURRENCIE:
		return {
			...state,
			localCurrencie: action.payload
		}
		default:
		return state;
	}
};

export default settings;
