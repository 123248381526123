import {
    MICE_SAVE,
    MICE_DATA_SAVED,
} from "constants/ActionTypes";

const INIT_STATE = {
    miceLoading: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case MICE_SAVE: {
            return {
                ...state,
                miceLoading:true,
                payload:1
            }
        }
        case MICE_DATA_SAVED: {
            return {
                ...state,
                miceLoading:false,
            }
        }
        default:
            return state;
    }
}
