import {all, call, fork, put, takeEvery} from "redux-saga/effects";

import {
	dataSearched,
	servicioDetailLoaded,
	setConfService,
	setPrice,
	responseSaveCheckout,
	clearCart,
	clearResponseCheckout,
	editModeOff,
	dataHotelSearched,
	acomodacionResponse,
	zonasResponse,
	priceAcomodacionResponse,
	hotelDisponibilidadResponse,
	hotelMultipleDisponibilidadesResponse,
	responseSaveCotizacion,
	responseReservarCotizacion,
	errorReservarCotizacion
} from "actions/Booking";

import {
	showModalError,
	disabledButtonSearch
} from "actions/App";

import Api from "util/Api";
import {
	BOOKING_SEARCH_LOAD,
	LOAD_SERVICIO_DETAIL,
	RESERV_CONF_OF_SERVICE_QUERY,
	RESERV_PRICE_QUERY,
	RESERV_QUOTE_SAVE,
	QUOTE_CHECKOUT_SAVE,
	RESERV_CHECKOUT_SAVE,
	BOOKING_SEARCH_HOTEL_LOAD,
	HOTEL_ACOMODACION_REQUEST,
	HOTEL_ZONAS_REQUEST,
	HOTEL_PRICE_ACOMODACION_REQUEST,
	HOTEL_DISPONIBILIDAD_REQUEST,
	HOTEL_DISPONIBILIDADES_REQUEST,
	SEARCH_DESTINATION_POINT
} from "../constants/ActionTypes";


// servicio/fecha --------------

const getConfServiceRequest = async (payload) =>
	await  Api.get("site/servicio/fecha",payload)
		.then(authUser => authUser)
		.catch(error => error);


export function* doGetConfSerivce() {
	yield takeEvery(RESERV_CONF_OF_SERVICE_QUERY, function*({payload,callback}) {
		try {
			const response = yield call(getConfServiceRequest,payload);
			if (response.response.status === 200) {
				yield put(setConfService(response));
			} else {
				yield put(showModalError(response.response.status,'modal'));
			}
		} catch (error) {
		}
	});
}

// servicio/precio --------------

const getPriceRequest = async (payload) =>
	await  Api.get("site/servicio/precio",payload)
		.then(authUser => authUser)
		.catch(error => error);


export function* doGetPrice() {
	yield takeEvery(RESERV_PRICE_QUERY, function*({payload,callback}) {
		try {
			const response = yield call(getPriceRequest,payload);
			if (response.response.status === 200) {
				yield put(setPrice(response));
			} else {
				alert(response.response.status)
			}
		} catch (error) {
		}
	});
}

// reserva/cotizacion --------------

const reservarCotizacionRequest = async (payload) =>
	await  Api.post("site/reservas/cotizacion/reservar",payload)
		.then(authUser => authUser)
		.catch(error => error);


export function* doReservarCotizacion() {
	yield takeEvery(RESERV_QUOTE_SAVE, function*({payload}) {
		try {
			const response = yield call(reservarCotizacionRequest,payload);
			if (response.response.status === 200) {
				yield [
					put(responseReservarCotizacion(response)),
				];
			} else {
				yield [
					put(errorReservarCotizacion(response.errors))
				];
			}
		} catch (error) {
		}
	});
}

// reserva/cotizacion --------------

export function* doSaveCotizacion() {
	yield takeEvery(QUOTE_CHECKOUT_SAVE, function*({payload}) {
		try {
			const response = yield call(saveReservaRequest,payload);
			console.log('response cotizacion', response)
			if (response.response.status === 200) {
				yield [
					put(responseSaveCotizacion(response)),
					put(clearCart()),
				];
			} else {
				yield [
					put(showModalError(response.response.status,'modal'))
				];
			}
		} catch (error) {
		}
	});
}

// reserva/checkout --------------

const saveReservaRequest = async (payload) =>
	await  Api.post("site/reserva/checkout",payload)
		.then(authUser => authUser)
		.catch(error => error);


export function* doSaveReserva() {
	yield takeEvery(RESERV_CHECKOUT_SAVE, function*({payload}) {
		try {
			const response = yield call(saveReservaRequest,payload);
			if (response.response.status === 200) {
				yield [
					put(responseSaveCheckout(response)),
					put(clearCart()),
					put(editModeOff()),
				];
			} else {
				yield [
					put(clearResponseCheckout(true)),
					// put(showModalError(response.response.status,'modal'))
				];
			}
		} catch (error) {
		}
	});
}

// busqueda--------------

const searchDataFnRequest = async (payload) =>
	await  Api.get("busqueda",payload)
		.then(authUser => authUser)
		.catch(error => error);



export function* searchData() {
	yield takeEvery(BOOKING_SEARCH_LOAD, function*({payload,callback}) {
		try {
			const response = yield call(searchDataFnRequest,payload);
			if (response.response.status === 200) {
				yield put(dataSearched(response));
				yield put(disabledButtonSearch(false));
			} else {
				yield put(disabledButtonSearch(false));
				yield put(showModalError(response.response.status,'modal','/home'));
			}
		} catch (error) {
		}
	});
}


// busqueda pero de hoteles--------------

const searchHotelDataFnRequest = async (payload) =>
	await  Api.get("frontend/reserva/hoteles",payload)
		.then(authUser => authUser)
		.catch(error => error);

export function* searchHotelData() {
	yield takeEvery(BOOKING_SEARCH_HOTEL_LOAD, function*({payload,callback}) {
		try {
			const response = yield call(searchHotelDataFnRequest,payload);
			if (response.response.status === 200) {
				yield put(dataHotelSearched(response));
				yield put(disabledButtonSearch(false));
			} else {
				yield put(disabledButtonSearch(false));
				yield put(showModalError(response.response.status,'modal','/home'));
			}
		} catch (error) {
		}
	});
}

// detalle servicio --------------

const loadServicioDetailFnRequest = async (payload) =>
	await  Api.get("detalle-servicio",payload)
		.then(authUser => authUser)
		.catch(error => error);


export function* loadServicioDetail() {
	yield takeEvery(LOAD_SERVICIO_DETAIL, function*({payload,callback}) {
		try {
			const response = yield call(loadServicioDetailFnRequest,payload);
			if (response.response.status === 200) {
				yield put(servicioDetailLoaded(response));
			} else {
				yield put(showModalError(response.response.status,'modal','/home'));
			}
		} catch (error) {

		}
	});
}

// --------------


// acomodacion servicio --------------

const loadAcomodacionesFnRequest = async (payload) =>
await  Api.get("frontend/reserva/hotel/acomodaciones",payload)
	.then(authUser => authUser)
	.catch(error => error);


export function* loadAcomodaciones() {
yield takeEvery(HOTEL_ACOMODACION_REQUEST, function*({payload,callback}) {
	try {
		const response = yield call(loadAcomodacionesFnRequest,payload);
		if (response.response.status === 200) {
			yield put(acomodacionResponse(response.items));
		} else {
			alert(response.response.status)
		}
	} catch (error) {

	}
});
}

// --------------


// zonas servicio --------------

const loadZonasFnRequest = async (payload) =>
await  Api.get("frontend/reserva/zonas",payload)
	.then(authUser => authUser)
	.catch(error => error);


export function* loadZonas() {
yield takeEvery(HOTEL_ZONAS_REQUEST, function*({payload,callback}) {
	try {
		const response = yield call(loadZonasFnRequest,payload);
		if (response.response.status === 200) {
			yield put(zonasResponse(response.items));
		} else {
			alert(response.response.status)
		}
	} catch (error) {

	}
});
}

// --------------

// Traer precio --------------

const loadPriceAcomodacionFnRequest = async (payload) =>
await  Api.get("frontend/reserva/acomodacion/price",payload)
	.then(authUser => authUser)
	.catch(error => error);

export function* loadPriceAcomodacion() {
	yield takeEvery(HOTEL_PRICE_ACOMODACION_REQUEST, function*({payload, callback}) {
		try {
			const response = yield call(loadPriceAcomodacionFnRequest, payload);
			if (response.response.status === 200) {
				callback.call(null,response);
				yield put(priceAcomodacionResponse(response.items));
			} else {
				yield put(showModalError(response.response.status,'modal'));
			}
		} catch (error) {
		}
	});
}

// --------------

// Validar disponibilidad --------------

const validarDisponibilidadFnRequest = async (payload) =>
await  Api.get("site/hotel/disponibilidad/validacion",payload)
	.then(authUser => authUser)
	.catch(error => error);

export function* validarDisponibilidad() {
	yield takeEvery(HOTEL_DISPONIBILIDAD_REQUEST, function*({payload, callback}) {
		try {
			const response = yield call(validarDisponibilidadFnRequest, payload);
			if (response.response.status === 200) {
				callback.call(null,response);
				yield put(hotelDisponibilidadResponse(response.items));
			} else {
				yield put(showModalError(response.response.status,'modal'));
			}
		} catch (error) {
		}
	});
}

// --------------

// Validar disponibilidad --------------

const validarMultipleDisponibilidadesFnRequest = async (payload) =>
await  Api.post("site/hotel/disponibilidad/validaciones",payload)
	.then(authUser => authUser)
	.catch(error => error);

export function* validarMultipleDisponibilidades() {
	yield takeEvery(HOTEL_DISPONIBILIDADES_REQUEST, function*({payload, callback}) {
		try {
			const response = yield call(validarMultipleDisponibilidadesFnRequest, payload);
			if (response.response.status === 200) {
				callback.call(null,response);
				yield put(hotelMultipleDisponibilidadesResponse(response.data));
			} else {
				yield put(showModalError(response.response.status,'modal'));
			}
		} catch (error) {
		}
	});
}

// Validar disponibilidad --------------

const searchDestinationPointFnRequest = async (payload) =>
await  Api.get("site/servicio/puntos",payload)
	.then(authUser => authUser)
	.catch(error => error);

export function* searchDestinationPoint() {
	yield takeEvery(SEARCH_DESTINATION_POINT, function*({payload, callback}) {
		try {
			const response = yield call(searchDestinationPointFnRequest, payload);
			if (response.response.status === 200) {
				callback.call(null,response);
				yield put(hotelMultipleDisponibilidadesResponse(response.data));
			} else {
				// alert(response.response.status)
			}
		} catch (error) {
		}
	});
}

// --------------

export default function* rootSaga() {
	yield all([
		fork(doGetConfSerivce),
		fork(searchData),
		fork(searchHotelData),
		fork(loadServicioDetail),
		fork(doGetPrice),
		fork(doSaveCotizacion),
		fork(doReservarCotizacion),
		fork(doSaveReserva),
		fork(loadAcomodaciones),
		fork(loadZonas),
		fork(loadPriceAcomodacion),
		fork(validarDisponibilidad),
		fork(validarMultipleDisponibilidades),
		fork(searchDestinationPoint)
	]);
}