import {
    BLOG_LOAD_DATA,
    BLOG_DATA_LOADED,
    BLOG_LOAD_LATEST,
    BLOG_LATEST_LOADED
} from 'constants/ActionTypes';

export const loadPosts = (page) => {
    return {
        type: BLOG_LOAD_DATA,
        payload: page
    };
};

export const blogDataLoaded = (data) => {
    return {
        type: BLOG_DATA_LOADED,
        payload: data
    };
};

export const loadLatest = (page) => {
    return {
        type: BLOG_LOAD_LATEST,
        payload: page
    };
};

export const latestLoaded = (data) => {
    return {
        type: BLOG_LATEST_LOADED,
        payload: data
    };
};


