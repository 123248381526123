import React from 'react';
import {Redirect, Route} from 'react-router-dom';

export default ({component: Component, logged,pages, authUser, ...rest}) =>
  <Route
    {...rest}
    render={props =>
	  !logged
		? <Redirect
		to={{
		  pathname: '/signin',
		  state: {from: props.location}
		}}
	  />
	  : <Component {...props} logged={logged} />}
/>;