import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import Api from "util/Api";
import { workWithUsSaved } from "../actions/WorkWithUs";
import { handleRequestException } from "../actions/App";
import { SUBMIT_WORK_WITH_US_FORM } from "../constants/ActionTypes";


const workWithUsSubmitFnRequest = async (payload) =>
	await Api.upload("WorkWithUs", payload)
		.then(authUser => authUser)
		.catch(error => error);


export function* workWithUsSubmit() {
	yield takeEvery(SUBMIT_WORK_WITH_US_FORM, function* ({ payload, cb, origen }) {

		try {
			const response = yield call(workWithUsSubmitFnRequest, payload);
			if (response.response.status === 200) {
				cb();
				yield put(workWithUsSaved(response));
			} else {
				yield [
					put(workWithUsSaved(response)),
					put(handleRequestException(response, origen))
				];
			}
		} catch (error) {
			console.error(error)
		}
	});
}

export default function* rootSaga() {
	yield all([
		fork(workWithUsSubmit)
	]);
}