import {
    SUBMIT_WORK_WITH_US_FORM,
    WORK_WITH_US_FORM_SUBMITTED
} from 'constants/ActionTypes';

export const submitWorkWithUsForm = (page, callback, origen) => {
    return {
        type: SUBMIT_WORK_WITH_US_FORM,
		payload: page,
		cb: callback,
		origen
    };
};
export const workWithUsSaved = (data) => {
    return {
        type: WORK_WITH_US_FORM_SUBMITTED,
        payload: data
    };
};
