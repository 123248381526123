import {all, call, fork, put, takeEvery} from "redux-saga/effects";

import {blogDataLoaded,latestLoaded} from "actions/Blog";
import Api from "util/Api";
import { BLOG_LOAD_DATA,BLOG_LOAD_LATEST } from "../constants/ActionTypes";


const loadPostsFnRequest = async (payload) =>
    await  Api.get("frontend/blog",payload)
        .then(authUser => authUser)
        .catch(error => error);



function* loadPostsFn({payload}) {
    try {
        const response = yield call(loadPostsFnRequest,payload);
        if (response.response.status === 200) {
            yield put(blogDataLoaded(response));
        } else {
            // alert(response.response.status)
        }
    } catch (error) {
    }
}


export function* loadPosts() {
    yield takeEvery(BLOG_LOAD_DATA, loadPostsFn);
}

//LOAD LATEST

const loadLatestFnRequest = async (payload) =>
    await  Api.get("frontend/blog/latest",payload)
        .then(authUser => authUser)
        .catch(error => error);



function* loadLatestFn({payload}) {
    try {
        const response = yield call(loadLatestFnRequest,payload);
        if (response.response.status === 200) {
            yield put(latestLoaded(response.data));
        } else {
            // alert(response.response.status)
        }
    } catch (error) {
    }
}


export function* loadLatest() {
    yield takeEvery(BLOG_LOAD_LATEST, loadLatestFn);
}
//LOAD LATEST

export default function* rootSaga() {
    yield all([
        fork(loadPosts),
        fork(loadLatest)
    ]);
}