import {
	GET_PAYMENT_STATUS,
	PAYMENT_STATUS_LOADED,
} from 'constants/ActionTypes';

export const getPaymentStatus = (page) => {
	return {
		type: GET_PAYMENT_STATUS,
		payload: page,
	};
};

export const paymentStatusLoaded = (data) => {
	return {
		type: PAYMENT_STATUS_LOADED,
		payload: data
	};
};