import {all, call, put, fork, takeEvery} from "redux-saga/effects";

import Api from "util/Api";
import { SWITCH_LANGUAGE,SWITCH_CURRENCY,GET_GLOBAL_CONFIG } from "../constants/ActionTypes";
import { setGlobalConfig } from "../actions/Setting";


const switchLangFnRequest = async (payload) =>
	await  Api.put("lang",payload)
		.then(authUser => authUser)
		.catch(error => error);



function* switchLangFn({payload,noSave}) {
	if(noSave) return;
	try {
		yield call(switchLangFnRequest,payload);

	} catch (error) {
	}
}


export function* switchLang() {
	yield takeEvery(SWITCH_LANGUAGE, switchLangFn);
}

const switchCurrencyFnRequest = async (payload) =>
	await  Api.put("currency",payload)
		.then(authUser => authUser)
		.catch(error => error);



function* switchCurrencyFn({payload,noSave}) {
	if(noSave) return;
	try {
		yield call(switchCurrencyFnRequest,payload);

	} catch (error) {
	}
}


export function* switchCurrency() {
	yield takeEvery(SWITCH_CURRENCY, switchCurrencyFn);
}

///

const requestConfig = async () =>
	await  Api.get("entorno/config-general-front")
		.then(authUser => authUser)
		.catch(error => error);


export function* sagasConfig() {
	yield takeEvery(GET_GLOBAL_CONFIG, function*({callback}) {
		try {
			const response = yield call(requestConfig);
			if (response.response.status === 200) {
				callback.call(response.data);
				yield put(setGlobalConfig(response.data));
			} else {
				// alert(response.response.status)
			}
		} catch (error) {
		}
	});
}

///

export default function* rootSaga() {
	yield all([
		fork(switchLang),
		fork(switchCurrency),
		fork(sagasConfig)
	]);
}