import {
    SUBMIT_CONTACT_FORM,
    CONTACT_FORM_SUBMITED
} from 'constants/ActionTypes';

export const submitContactForm = (page, origen) => {
    return {
        type: SUBMIT_CONTACT_FORM,
		payload: page,
		origen
    };
};
export const contactSaved = (data) => {
    return {
        type: CONTACT_FORM_SUBMITED,
        payload: data
    };
};